import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';
import { Link } from "react-router-dom";
import { useFetchOperation } from '../../service/Operation';
import { User } from '../../types/Common';
import { LocalDateTime } from '../formatters/DateTimeFormat';
import AutoPaginationTable from '../general/AutoPaginationTable';
import Error from '../general/Error';
import Loader from '../general/Loader';

export default function UserList() {
    const [users, setUsers] = React.useState<User[]>();

    const [getting, startGetting] = useFetchOperation(setUsers, undefined, true);

    React.useEffect(() => {
        startGetting('get', `api/user/all`);
        document.title = `All users - ${(document as any).rootTitle}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <h1 className='mb-3'>All users</h1>
            {getting.active ?
                <Loader />
                :
                !users?.length ?
                    <Error text={getting.error} />
                    :
                    <AutoPaginationTable
                        columns={columns}
                        data={users} />
            }
        </>
    )
}

const columns: ColumnDef<User>[] = [
    {
        header: 'Id',
        accessorKey: 'id',
        cell: props => <Link to={`/user/${props.row.original.id}`}>{props.row.original.id}</Link>
    },
    {
        header: 'Username',
        accessorKey: 'userName',
    },
    {
        header: 'Email',
        accessorKey: 'email',
    },
    {
        header: 'Email confirmed',
        accessorKey: 'emailConfirmed',
        cell: props => <span>{props.row.original.emailConfirmed ? '✓' : '-'}</span>
    },
    {
        header: 'UI language',
        accessorKey: 'language',
    },
    {
        header: 'Mode for studying',
        accessorKey: 'currentModes',
        cell: props =>
            <span>{props.row.original.currentModes?.map((mode, i) =>
                <span key={i}>{i === 0 ? '' : ', '}<Link to={`/dictionaries/${mode.dictionaryId}`}>{mode.dictionaryName}</Link>{mode.isDirect ? '' : ' (inv.)'}</span>)}
                {props.row.original.mixedModesLimit ? ', mix lim.' : ''}
            </span>
    },
    {
        header: 'Role',
        accessorKey: 'role',
        cell: props => <Link to={`/user/${props.row.original.id}/role`}>{props.row.original.role}</Link>
    },
    {
        header: 'Last activity',
        accessorKey: 'lastActivity',
        cell: props => <LocalDateTime value={props.row.original.lastActivity} />
    },
];
