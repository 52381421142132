import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';
import { Chart } from 'react-google-charts';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useFetchOperation } from '../../../service/Operation';
import { CardResult, UserMode } from '../../../types/Common';
import { ModeStat } from '../../../types/StatTypes';
import AutoPaginationTable from '../../general/AutoPaginationTable';
import Error from '../../general/Error';
import Loader from '../../general/Loader';
import ModeStatBox from './ModeStatBox';
import UserPanel from './../UserPanel';

interface UserStatPage {
    userMode: UserMode,
    modeStat: ModeStat,
    strengthData: number[][],
    updatedCountData: number[][],
    daysData: DayData[],
    weakResults: CardResult[],
    maxUpdatedResults: CardResult[],
}

interface DayData {
    date: string,
    startCount: number,
    updateCount: number,
    sum: number
}

export default function UserStat() {
    const params = useParams();
    const contanerRef = React.useRef(null);
    const [searchParams] = useSearchParams();
    const [userStat, setUserStat] = React.useState<UserStatPage>();

    const [getting, startGetting] = useFetchOperation(onGetSuccess, undefined, true);
    const darkTheme = window.matchMedia("(prefers-color-scheme: dark)")?.matches;

    React.useEffect(() => {
        const isDirect = searchParams.get('direct')?.toLowerCase() !== 'false';
        startGetting('get', `api/user/${params.id}/stat?dictionaryId=${params.dictionaryId}&isDirect=${isDirect}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, searchParams]);

    function onGetSuccess(data: UserStatPage) {
        if (data.daysData) {
            let counter = 0;
            for (var i = 0; i < data.daysData.length; i++) {
                counter += data.daysData[i].startCount;
                data.daysData[i].sum = counter;
            }
        }
        setUserStat(data);
        document.title = `${data.userMode.userName} - ${data.userMode.dictionaryName} ${data.userMode.isDirect ? '' : '(inverse) '}- Statistics - ${(document as any).rootTitle}`;
    }

    const ranksDataOptions: any = React.useMemo(() => ({
        hAxis: {
            title: "Strength",
            gridlines: { count: 10 },
            titleTextStyle: { italic: false }
        },
        vAxis: {
            title: "Cards",
            titleTextStyle: { italic: false }
        },
        series: {
            0: { type: 'bars' },
        },
        legend: "none",
        fontName: 'Segoe UI',
        chartArea: { width: '90%', height: '80%', top: 8, left: 50 },
        backgroundColor: darkTheme ? '#1d1d1d' : 'white'
    }), [darkTheme]);

    const updationCountOptions: any = React.useMemo(() => ({
        hAxis: {
            title: "Strength",
            gridlines: { count: 10 },
            titleTextStyle: { italic: false },
            minValue: 0
        },
        vAxis: {
            title: "Cards",
            titleTextStyle: { italic: false },
            minValue: 0
        },
        series: {
            0: { type: 'bars' },
        },
        legend: "none",
        fontName: 'Segoe UI',
        chartArea: { width: '90%', height: '80%', top: 8, left: 50 },
        backgroundColor: darkTheme ? '#1d1d1d' : 'white'
    }), [darkTheme]);

    return (
        <>
            <div ref={contanerRef} className="w-100 d-flex flex-column"></div>

            <UserPanel userMode={userStat?.userMode} />

            {getting.active ?
                <Loader />
                :
                getting.error ?
                    <Error text={getting.error} />
                    :
                    !userStat?.modeStat ?
                        <p className="my-3"> No results found</p>
                        :
                        <div className="w-100">
                            <ModeStatBox modeStat={userStat.modeStat} />

                            {!!userStat.modeStat.studied
                                &&
                                <>
                                    <h2>Distribution of cards strength</h2>

                                    <Chart chartType="LineChart"
                                        data={[['x', 'count'], ...userStat.strengthData]}
                                        width="100%"
                                        height="280px"
                                        options={ranksDataOptions} />

                                    <h2>Distribution of number of reviews</h2>

                                    <Chart chartType="LineChart"
                                        data={[['x', 'count'], ...userStat.updatedCountData]}
                                        width="100%"
                                        height="280px"
                                        options={updationCountOptions} />
                                </>
                            }

                            {!!userStat.weakResults?.length &&
                                <>
                                    <h2>The weakest cards</h2>

                                    <AutoPaginationTable className="my-3"
                                        columns={weakestColumns}
                                        data={userStat.weakResults} />

                                    <h2>Cards with maximal number reviews</h2>

                                    <AutoPaginationTable className="my-3"
                                        columns={weakestColumns}
                                        data={userStat.maxUpdatedResults} />
                                </>
                            }
                        </div>
            }
        </>
    )
}

const weakestColumns: ColumnDef<CardResult>[] = [
    {
        header: '#',
        accessorKey: 'id',
        cell: props => <Link to={`/cards/${props.row.original.card.id}`}>{props.row.index + 1}</Link>
    },
    {
        header: 'Challenge',
        accessorFn: c => c.card.challenge,
    },
    {
        header: 'Answer',
        accessorFn: c => c.card.answer,
    },
    {
        header: 'Strength',
        accessorKey: 'strength',
        cell: props => <Link to={`/results/${props.row.original.id}`}> {props.row.original.strength?.toFixed(4)}</Link>
    },
    {
        header: 'Reviews',
        accessorKey: 'updatedCount',
    },
];
