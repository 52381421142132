import { Button } from 'react-bootstrap';
import { useLongPress } from 'use-long-press';
import { InterpretValue } from '../../types/TrainingTypes';

const threshold = 500;
const cancelOnMovement = 5;

export default function InterpretAssess(props: { assess: (x: InterpretValue) => void }) {

    const longPress0 = useLongPress(() => { }, {
        threshold,
        cancelOnMovement,
        onFinish: x => x.type === 'pointerup' && props.assess(InterpretValue.Reset),
    });
    const longPress1 = useLongPress(() => { }, {
        threshold,
        cancelOnMovement,
        onFinish: x => x.type === 'pointerup' && props.assess(InterpretValue.MinimalDecrease),
    });
    const longPress2 = useLongPress(() => { }, {
        threshold,
        cancelOnMovement,
        onFinish: x => x.type === 'pointerup' && props.assess(InterpretValue.MinimalIncrease),
    });
    const longPress3 = useLongPress(() => { }, {
        threshold,
        cancelOnMovement,
        onFinish: x => x.type === 'pointerup' && props.assess(InterpretValue.KnowExcellent),
    });

    return (
        <div className="assess-panel">
            <div className="row g-2 g-sm-3">
                <div className="col-6 col-lg">
                    <Button variant="light"
                        size="lg"
                        className="px-0 text-nowrap overflow-hidden"
                        onClick={() => props.assess(InterpretValue.DoNotKnow)}
                        {...longPress0()}>
                        I don't know
                    </Button>
                </div>

                <div className="col-6 col-lg">
                    <Button variant="light"
                        size="lg"
                        className="px-0 text-nowrap overflow-hidden"
                        onClick={() => props.assess(InterpretValue.NotSure)}
                        {...longPress1()}>
                        I'm not sure
                    </Button>
                </div>

                <div className="col-6 col-lg">
                    <Button variant="light"
                        size="lg"
                        className="px-0 text-nowrap overflow-hidden"
                        onClick={() => props.assess(InterpretValue.Know)}
                        {...longPress2()}>
                        I know
                    </Button>
                </div>

                <div className="col-6 col-lg">
                    <Button variant="light"
                        size="lg"
                        className="px-0 text-nowrap overflow-hidden"
                        onClick={() => props.assess(InterpretValue.KnowWell)}
                        {...longPress3()}>
                        I know well
                    </Button>
                </div>
            </div>
        </div>
    );
}