import * as React from "react";
import {
    ColumnDef,
    OnChangeFn,
    PaginationState,
    SortingState,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import Pagination from './Pagination';

export default function ManualPaginationTable<D extends object>(props: {
    columns: ColumnDef<D>[],
    data: D[],
    totalItemsCount: number | undefined,
    isDataLoading: boolean,
    pagination: PaginationState,
    setPagination: OnChangeFn<PaginationState>,
    sorting: SortingState,
    setSorting: OnChangeFn<SortingState>,
    className?: string,
    getRowClassName?: (data: D) => string | undefined
}) {
    const { pageIndex, pageSize } = props.pagination
    const pageCount = Math.ceil((props.totalItemsCount ?? 0) / pageSize);

    const table = useReactTable({
        data: props.data,
        columns: props.columns,
        pageCount,
        state: {
            pagination: props.pagination,
            sorting: props.sorting,
        },
        onSortingChange: props.setSorting,
        onPaginationChange: props.setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        manualSorting: true,
        enableSortingRemoval: false,
        sortDescFirst: false,
    })

    return (
        <>
            {props.data?.length &&
                <div className={'w-100 ' + (props.className || '')}>
                    <Pagination className="mb-3"
                        currentPage={pageIndex + 1}
                        pageCount={pageCount}
                        totalItemsCount={props.totalItemsCount ?? 0}
                        goToPage={(x) => table.setPageIndex(x - 1)} />

                    {!props.isDataLoading &&
                        <>
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered table-sm text-center">
                                    <thead className="table-secondary align-middle">
                                        {table.getHeaderGroups().map(headerGroup => (
                                            <tr key={headerGroup.id}>
                                                {headerGroup.headers.map(header => {
                                                    return (
                                                        <th key={header.id}
                                                            colSpan={header.colSpan}>
                                                            {header.isPlaceholder ? null : (
                                                                <div
                                                                    {...{
                                                                        className: header.column.getCanSort()
                                                                            ? 'cursor-pointer user-select-none'
                                                                            : '',
                                                                        onClick: header.column.getToggleSortingHandler(),
                                                                    }}>
                                                                    {flexRender(
                                                                        header.column.columnDef.header,
                                                                        header.getContext()
                                                                    )}
                                                                    {{
                                                                        asc: ' 🔼',
                                                                        desc: ' 🔽',
                                                                    }[header.column.getIsSorted() as string] ?? null}
                                                                </div>
                                                            )}
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        ))}
                                    </thead>

                                    <tbody className={props.isDataLoading ? 'table-loading' : ''}>
                                        {table.getRowModel().rows.map(row => {
                                            if (props.getRowClassName)
                                                var clName = props.getRowClassName(row.original)
                                            return (
                                                <tr key={row.id} className={clName ?? ''}>
                                                    {row.getVisibleCells().map(cell =>
                                                        <td key={cell.id}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <Pagination className="mt-3"
                                currentPage={pageIndex + 1}
                                pageCount={pageCount}
                                totalItemsCount={props.totalItemsCount ?? 0}
                                goToPage={(x) => table.setPageIndex(x - 1)} />
                        </>
                    }
                </div>
            }
        </>
    )
}
