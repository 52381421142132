import * as React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { CardHandler, Dictionary, DictionaryData, Language } from '../../types/Common';
import { State } from '../../types/State';
import Alerts from '../general/Alerts';
import Error from '../general/Error';
import Loader from '../general/Loader';
import ProgressButton from '../general/ProgressButton';
import DicEditor from './DicEditor';
import DicPanel from './DicPanel';

interface EditDictionaryPage {
    dictionary: Dictionary,
    languages: Language[],
    cardHandlers: CardHandler[]
}

export default function DicEdit() {
    const auth = useSelector((state: State) => state.auth);
    const params = useParams();

    const [dictionary, setDictionary] = React.useState<Dictionary>();
    const [editDictionary, setEditDictionary] = React.useState<Dictionary>();
    const [languages, setLanguages] = React.useState<Language[]>();
    const [cardHandlers, setCardHandlers] = React.useState<CardHandler[]>();

    const [getting, startGetting] = useFetchOperation(onGetSuccess, undefined, true);
    const [editing, startEditing, resetEditing] = useFetchOperation(onEditSucccess);

    const [deleted, setDeleted] = React.useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [deleting, startDeleting, resetDeleting] = useFetchOperation(() => setDeleted(true));

    React.useEffect(() => {
        startGetting('get', `api/dictionaries/${params.id}/edit`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onGetSuccess(data: EditDictionaryPage) {
        if (auth.isModerator || data.dictionary.authorId === auth.user?.id) {
            setDictionary(data.dictionary);
            setEditDictionary(data.dictionary);
            setLanguages(data.languages);
            setCardHandlers(data.cardHandlers);
            document.title = `Edit - ${data.dictionary.name} - Dictionaries - ${(document as any).rootTitle}`;
        }
        else
            return "You don't have permissions to edit this dictionary";
    }

    function dicEditorOnChange(val: DictionaryData) {
        setEditDictionary({ ...editDictionary!, ...val });
    }

    function onEditClick() {
        startEditing('put', `api/dictionaries`, editDictionary)
    }

    function onEditSucccess() {
        setDictionary(editDictionary);
        setTimeout(resetEditing, 2000);
    }

    function onDeleteClick() {
        startDeleting('delete', `api/dictionaries/${dictionary!.id}`)
        setModalShow(false);
    }

    return (
        <>
            <DicPanel dictionary={dictionary} />

            {getting.active ?
                <Loader />
                :
                !(editDictionary && dictionary) ?
                    <Error text={getting.error} />
                    :
                    <div className="w-100">
                        <div className="width-limit">
                            <Form>
                                <DicEditor dictionary={editDictionary}
                                    languages={languages!}
                                    cardHandlers={cardHandlers}
                                    onChange={dicEditorOnChange} />

                                <div className="btn-panel">
                                    <ProgressButton className="btn-wide"
                                        onClick={onEditClick}
                                        active={editing.active}
                                        success={editing.success} />

                                    {auth.isAdmin &&
                                        <ProgressButton variant="outline-danger"
                                            className="btn-wide"
                                            onClick={() => setModalShow(true)}
                                            disabled={deleted}
                                            active={deleting.active}
                                            success={deleting.success}
                                            text="Delete" />
                                    }
                                </div>
                            </Form>

                            <Modal show={modalShow} onHide={() => setModalShow(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Danger!</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p className="fw-bold">Are you sure you want to delete dictionary '{dictionary.name}'?</p>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setModalShow(false)}>Cancel</Button>
                                    <Button variant="danger"
                                        onClick={onDeleteClick}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Alerts error={editing.error}
                                onClose={() => resetEditing()} />

                            <Alerts success={deleting.success}
                                error={deleting.error}
                                successContent="The dictionary was successfully deleted"
                                onClose={() => resetDeleting()} />

                        </div>
                    </div>
            }
        </>
    )
}
