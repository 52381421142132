import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { addingReducer } from './Adding';
import { authReducer } from './Auth';
import { choiceReducer } from './Choice';
import { combinedReducer } from './Combined';
import { dictionariesReducer } from './Dictionaries';
import { interpretReducer } from './Interpret';
import { modeReducer } from './Mode';
import { modesReducer } from './Modes';
import { statReducer } from './Stat';
import { trainingReducer } from './Training';

const store = configureStore({
    reducer: {
        auth: authReducer,
        stat: statReducer,
        modes: modesReducer,
        mode: modeReducer,
        training: trainingReducer,
        interpret: interpretReducer,
        choice: choiceReducer,
        combined: combinedReducer,
        adding: addingReducer,
        dictionaries: dictionariesReducer
    },
})

export default store

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => RootState): void;
}
