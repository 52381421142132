import * as React from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../store';
import { modesActionCreators } from '../../../store/Modes';
import { Mode } from '../../../types/Common';
import Loader from '../../general/Loader';
import { modeActionCreators } from './../../../store/Mode';

const Styles = styled.div`
ul{
    list-style-type: none;
    padding-left: 0;
}

li {    
    white-space: nowrap;
    border-bottom: 1px solid rgba(var(--bs-body-color-rgb), 0.3);
    cursor: pointer;
}

li > div{
    border-radius: 7px;
}`

export default function ModeStatList(props: { modeSelected: () => void }) {

    const stat = useAppSelector(state => state.stat);
    const modesState = useAppSelector(state => state.modes);
    const isAuthenticated = useAppSelector(state => !!state.auth.user);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (isAuthenticated
            && !stat.isModeStatLoading) {
            dispatch(modesActionCreators.getModes());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onModeClick(mode: Mode) {
        dispatch(modeActionCreators.changeMode(mode.dictionaryId, mode.isDirect));
        props.modeSelected();
    }

    return (
        <Styles>
            <div className='stat-dd'>
                {modesState.isModesLoading
                    ?
                    <Loader />
                    :
                    !modesState.modes.length
                        ?
                        <p className="my-3">No modes found</p>
                        :
                        <ul>
                            {modesState.modes.map((m) =>
                                <li onClick={() => onModeClick(m)}
                                    className={m.isCurrent ? 'fw-bold' : ''}
                                    key={m.dictionaryId.toString() + m.isDirect}>
                                    <div className="px-2 py-1">
                                        {m.dictionaryName} {!m.isDirect && '(inv.)'}    {m.ready}&thinsp;({m.weakDeltaSum.toFixed(0)}) | {m.memorized} / {m.studied} / {m.notSkipped}/{m.total}
                                    </div>
                                </li>
                            )}
                        </ul>
                }
            </div>
        </Styles>
    );
}
