import * as React from 'react';
import { Link, useLocation } from "react-router-dom";

export default function Preview() {
    const location = useLocation();

    React.useEffect(() => {
        document.title = (document as any).rootTitle;
    }, []);

    return (
        <div className="width-limit">
            <p className="fs-3 mt-0">Preview of new cards</p>
            <div className="row g-3 mb-3">
                <div className="col-12 col-md-6">
                    <Link to={'/preview/choice' + location.search}
                        className={'text-decoration-none btn btn-light btn-lg'}>
                        <h2>Choose the right variant</h2>
                        <p className="fs-5">In this mode you choose the suitable meaning of the phrase from four variants.</p>
                    </Link>
                </div>

                <div className="col-12 col-md-6">
                    <Link to={'/preview/interpret' + location.search}
                        className="text-decoration-none btn btn-light btn-lg">
                        <h2>Assess your knowledge</h2>
                        <p className="fs-5">In this mode you assess how well you know the phrase. No variants are given.</p>
                    </Link>
                </div>

                <div className="col-12 col-md-6">
                    <Link to={'/preview/combined' + location.search}
                        className="text-decoration-none btn btn-light btn-lg">
                        <h2>Combined</h2>
                        <p className="fs-5">Try to guess the meaning then check your guess by choosing from variants.</p>
                    </Link>
                </div>

                <div className="col-12 col-md-6">
                    <Link to={'/preview/auto' + location.search}
                        className="text-decoration-none btn btn-light btn-lg">
                        <h2>Automatic</h2>
                        <p className="fs-5">The type of training (Guess, Choice or combined) will be selected automatically</p>
                    </Link>
                </div>
            </div>
        </div>
    );
}
