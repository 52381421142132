import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { useAppSelector } from '../../store';
import { Card, DictionarySlim } from '../../types/Common';
import AutoPaginationTable from '../general/AutoPaginationTable';
import Error from '../general/Error';
import Loader from '../general/Loader';
import DicSelectPanel from './DicSelectPanel';
import SearchPanel from './SearchPanel';

export default function SearchCards() {
    const lastDictionaryId = useAppSelector(state => state.training.card?.modeInfo?.dictionaryId);

    const location = useLocation();
    const state = location.state as { text: string | undefined, full: boolean | undefined };
    const params = useParams();

    const [text, setText] = React.useState(state?.text ?? '');
    const [full, setFull] = React.useState(state?.full ?? false);
    const [dictionaryId, setDictionaryId] = React.useState<number>(params.dictionaryId ? parseInt(params.dictionaryId) : lastDictionaryId ?? 2);
    const [dictionaries, setDictionaries] = React.useState<DictionarySlim[]>([]);

    const [cards, setCards] = React.useState<Card[]>();

    const [, startGetting] = useFetchOperation(onDictionariesFetched, undefined, true);
    const [search, startSearch] = useFetchOperation(setCards);

    React.useEffect(() => {
        startGetting('get', `api/dictionaries/slim/`)
        if (state) {
            onSearch(state.text ?? '', state.full ?? false);
        }
        document.title = `Search cards - ${(document as any).rootTitle}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onDictionariesFetched(data: DictionarySlim[]) {
        setDictionaries(data);
    }

    async function onSearch(text: string, full: boolean) {
        setCards([]);
        setText(text);
        setFull(full);
        startSearch('get', `api/cards/search/${dictionaryId}?text=${text}&extended=${full}`);
        document.title = `${text} - Search cards - ${(document as any).rootTitle}`;
    }

    function onDictionaryChange(value: number) {
        setDictionaryId(value);
        window.history.replaceState(null, "", window.location.pathname.replace(/\d+/, value.toString()));
    }

    return (
        <>
            <h1>Search cards</h1>
            <Form className="width-limit">
                <SearchPanel
                    text={text}
                    full={full}
                    onSearch={onSearch}
                    autoFocus={!state} />
                <DicSelectPanel
                    dictionaries={dictionaries}
                    dictionaryId={dictionaryId}
                    onDictionaryChange={onDictionaryChange} />
            </Form>
            {
                search.active ?
                    <Loader />
                    :
                    search.error ?
                        <Error text={search.error} />
                        :
                        cards ?
                            cards.length ?
                                <AutoPaginationTable className="mb-3"
                                    columns={full ? extendedColumns : simpleColumns}
                                    data={cards} />
                                :
                                <p className="my-3">No cards found</p>
                            :
                            <></>
            }
        </>
    );
}

const simpleColumns: ColumnDef<Card>[] = [
    {
        header: '#',
        accessorKey: 'id',
        cell: props => <Link to={`/cards/${props.row.original.id}`}> {props.row.index + 1}</Link>
    },
    {
        header: 'Challenge',
        accessorKey: 'challenge',
    },
    {
        header: 'Answer',
        accessorKey: 'answer',
    },
];

const extendedColumns: ColumnDef<Card>[] = [
    {
        header: '#',
        accessorKey: 'id',
        cell: props => <Link to={`/cards/${props.row.original.id}`}> {props.row.index + 1}</Link>
    },
    {
        header: 'Challenge',
        accessorKey: 'challenge',
    },
    {
        header: 'ChallengeAux',
        accessorKey: 'challengeAux',
    },
    {
        header: 'Answer',
        accessorKey: 'answer',
    },
    {
        header: 'Example',
        accessorKey: 'example',
    },
];
