import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '.';
import { TaskKind, TrainingCard, TrainingCardData, TrainingType, Variant } from '../types/TrainingTypes';
import { trainingActionCreators } from './Training';

const interpretSeconds = 5;

interface CombinedState {
    variantCards: TrainingCard[],
    task: string,
    taskAux: string | undefined,
    solution: string,
    solutionAux: string,
    variants: Variant[],
    chosenVariant: Variant | undefined,
    wrongVariant: Variant | undefined,
    backgrounds: string[],
    shownTime: string | undefined,
    isTimerElapsed: boolean,
    isVariantsShown: boolean,
    interval: number
}

const initialState: CombinedState = {
    variantCards: [],
    task: ' ',
    taskAux: '',
    solution: '',
    solutionAux: '',
    variants: [],
    chosenVariant: undefined,
    wrongVariant: undefined,
    backgrounds: ['', '', '', ''],
    shownTime: undefined,
    isTimerElapsed: false,
    isVariantsShown: false,
    interval: interpretSeconds,
}

const slice = createSlice({
    name: 'combined',
    initialState,
    reducers: {
        setNextCard: (state, action: PayloadAction<{ card: TrainingCard, shownTime: string }>) => {
            const { card, shownTime } = action.payload;

            state.variantCards = [card, ...card.variants!];
            state.shownTime = shownTime;
            state.isTimerElapsed = false;
            state.isVariantsShown = false;

            updateCardParts(state, card, false);
            updateUserChoice(state, card, false)
        },

        setInterval: (state, action: PayloadAction<number>) => {
            state.interval = action.payload;
            if (action.payload === 0 && !state.isVariantsShown)
                state.isTimerElapsed = true;
        },

        showVariants: (state, action: PayloadAction<{ card: TrainingCard, isTimerElapsed: boolean }>) => {
            state.isVariantsShown = true;
            state.isTimerElapsed = action.payload.isTimerElapsed;
            updateCardParts(state, action.payload.card, true)
        },

        chooseVariant: (state, action: PayloadAction<{ variant: Variant | undefined, card: TrainingCard }>) => {
            state.chosenVariant = action.payload.variant;
            updateUserChoice(state, action.payload.card, true)
        },

        resetTimer: (state, action: PayloadAction<string>) => {
            state.shownTime = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase('training/reset', (state, action) => {
            return initialState;
        });

        builder.addCase('training/refreshCardData', (state, action) => {
            const data = (action as PayloadAction<TrainingCardData>).payload;
            if (data.card?.trainingType === TrainingType.Combined) {
                updateCardParts(state, data.card!, data.isSolutionShown);
                updateUserChoice(state, data.card!, data.isSolutionShown)
            }
        });
    }
})

function updateCardParts(state: CombinedState, card: TrainingCard, isVariantsShown: boolean) {
    let taskKind = card.modeInfo.modeSetting.taskKind;
    if (taskKind === TaskKind.AuxOnly && !card.modeInfo.onlyChallengeAuxAvailable)
        taskKind = TaskKind.BothAlways;

    const showAux = taskKind === TaskKind.BothAlways
        || taskKind === TaskKind.BothOnCombinedAndInterpret
        || taskKind === TaskKind.AuxOnly
        || (taskKind === TaskKind.BothOnRevealed && isVariantsShown);
    const onlyAux = taskKind === TaskKind.AuxOnly;

    state.variants = state.variantCards
        .map(vc => {
            const ac = vc.answer;
            const acAux = '';
            const content = onlyAux && card.modeInfo.onlyChallengeAuxAvailable && acAux ? acAux : ac;
            const contentAux = showAux && !(onlyAux && card.modeInfo.onlyChallengeAuxAvailable) ? acAux : '';
            const wrongVariant = vc.challenge;
            const width = getTextWidth(content, 20) + getTextWidth(contentAux, 15);
            return {
                content,
                contentAux,
                wrongVariant,
                card: vc,
                width
            };
        })
        .sort((a, b) => a.width - b.width);

    const task = card ? card.challenge : ' ';
    const taskAux = card ? card.challengeAux : '';
    const solution = card ? card.answer : '';
    const solutionAux = card ? '' : '';

    state.task = onlyAux && card.modeInfo.onlyChallengeAuxAvailable && taskAux ? taskAux : task;
    state.taskAux = showAux && !(onlyAux && card.modeInfo.onlyChallengeAuxAvailable) ? taskAux : '';
    state.solution = onlyAux && card.modeInfo.onlyChallengeAuxAvailable && solutionAux ? solutionAux : solution;
    state.solutionAux = showAux && !(onlyAux && card.modeInfo.onlyChallengeAuxAvailable) ? solutionAux : '';
}

function updateUserChoice(state: CombinedState, card: TrainingCard, isSolutionShown: boolean) {
    if (isSolutionShown) {
        const chosenIndex = state.chosenVariant ? state.variants.findIndex(x => x.card.id === state.chosenVariant?.card.id) : -1;
        const isCorrect = state.chosenVariant?.content === state.solution;

        const backgrounds = ['', '', '', ''];
        const correctIndex = state.variants.findIndex(x => x.card.id === card.id);
        backgrounds[isCorrect ? chosenIndex : correctIndex] = 'ch-green';
        if (!isCorrect)
            backgrounds[chosenIndex === -1 ? backgrounds.length : chosenIndex] = 'ch-red';

        state.backgrounds = backgrounds;
        state.wrongVariant = !isCorrect && chosenIndex !== -1 ? state.variants[chosenIndex] : undefined;
    }
    else {
        state.backgrounds = ['', '', '', ''];
        state.wrongVariant = undefined;
    }
}

function getTextWidth(text: string, size: number) {
    const canvas = (getTextWidth as any).canvas || ((getTextWidth as any).canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = `bold ${size}pt arial`;
    const metrics = context.measureText(text);
    return metrics.width as number;
}

const setNextCard = (card: TrainingCard) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(slice.actions.setNextCard({ card: card, shownTime: new Date().toISOString() }));

        let seconds = interpretSeconds;
        dispatch(slice.actions.setInterval(seconds));
        const intervalId = setInterval(() => {
            seconds--;
            if (getState().training.card?.id === card!.id)
                dispatch(slice.actions.setInterval(seconds));

            if (seconds === 0)
                clearInterval(intervalId);
        }, 1000);
    }
}

const showVariants = (isTimerElapsed: boolean) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const training = getState().training;
        dispatch(slice.actions.showVariants({ card: training.card!, isTimerElapsed }));
    }
}

const chooseVariant = (variant: Variant | undefined) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const training = getState().training;
        const state = getState().combined;

        const isMistake = variant?.content !== state.solution;
        dispatch(trainingActionCreators.showSolution(isMistake));
        dispatch(slice.actions.chooseVariant({ variant, card: training.card! }));
    }
};

const resetTimer = () => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(slice.actions.resetTimer(new Date().toISOString()));
    }
}

export const combinedReducer = slice.reducer;

export const combinedActionCreators = {
    setNextCard,
    showVariants,
    chooseVariant,
    resetTimer
}
