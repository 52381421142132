import React, { useRef } from 'react';

export default function TabTextarea(props: {
    text: string,
    placeholder?: string,
    onChange: (x: string) => void
}) {
    const codeAreaRef = useRef<HTMLTextAreaElement>(null);

    return (
        <textarea
            value={props.text}
            ref={codeAreaRef}
            className="form-control fs-5 my-3"
            style={{ minHeight: '250px' }}
            placeholder={props.placeholder}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                props.onChange(e.target.value);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                if (e.key === 'Tab') {
                    e.preventDefault();

                    const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;

                    const newText = props.text ?
                        props.text.substring(0, selectionStart) + '\t' + props.text.substring(selectionEnd, props.text.length) : '\t';

                    codeAreaRef.current!.focus();
                    codeAreaRef.current!.value = newText;

                    codeAreaRef.current!.setSelectionRange(
                        selectionStart + 1,
                        selectionStart + 1
                    );

                    props.onChange(newText);
                }
            }}
        />
    )
}
