import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { authActionCreators } from '../../store/Auth';
import HoverDropDown from '../layout/HoverDropDown';

export default function LoginMenu(props: { closeNav: () => void }) {
    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth);
    const { id, name } = auth.user ?? {};
    function logout() {
        props.closeNav();
        dispatch(authActionCreators.logout());
    }

    return (
        <>
            {id ?
                <>
                    <Nav.Link as={Link} to={`user/${id}`} className="d-block d-sm-none" onClick={props.closeNav}>{name} - statistics</Nav.Link>
                    <Nav.Link as={Link} to="studying-settings" className="d-block d-sm-none ms-3" onClick={props.closeNav}>Studying settings</Nav.Link>
                    {/*<Nav.Link as={Link} to="user/settings" className="d-block d-sm-none ms-3" onClick={props.closeNav}>Account settings</Nav.Link>*/}
                    {auth.isAdmin &&
                        <Nav.Link as={Link} to="user/list" className="d-block d-sm-none ms-3" onClick={props.closeNav}>All users</Nav.Link>}
                    <Nav.Link as={Link} to="/" className="d-block d-sm-none ms-3" onClick={logout}>Log out</Nav.Link>

                    <HoverDropDown titleText={name ?? ''}
                        titleLinkTo={`user/${id}`}
                        className="d-none d-sm-block">
                        <NavDropdown.Item as={Link} to={`user/${id}`}>Statistics</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="studying-settings">Studying settings</NavDropdown.Item>
                        {/*<NavDropdown.Item as={Link} to="user/settings">Account settings</NavDropdown.Item>*/}
                        {auth.isAdmin &&
                            <NavDropdown.Item as={Link} to="user/list">All users</NavDropdown.Item>}
                        <NavDropdown.Item as={Link} to="/" onClick={logout}>Log out</NavDropdown.Item>
                    </HoverDropDown >
                </>
                :
                <>
                    {/*<Nav.Link as={Link} to="register" onClick={props.closeNav}>Register</Nav.Link>*/}
                    <Nav.Link as={Link} to="signin" onClick={props.closeNav}>Sign in</Nav.Link>
                </>
            }
        </>
    )
}
