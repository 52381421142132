import { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Link, Navigate } from 'react-router-dom';
import { ExtendedCard, Result } from '../../types/Common';
import AutoPaginationTable from '../general/AutoPaginationTable';
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useFetchOperation } from '../../service/Operation';
import Loader from '../general/Loader';
import Error from '../general/Error';
import { LocalDateTime } from '../formatters/DateTimeFormat';

interface CardResultsPage {
    card: ExtendedCard
    results: UserResult[] | undefined
}

interface UserResult extends Result {
    userId: number,
    userName: string,
    isDirect: boolean
}

export default function CardResults() {
    const params = useParams();

    const [pageData, setPageData] = useState<CardResultsPage>();
    const [getting, startGetting] = useFetchOperation(setPageData, undefined, true);

    useEffect(() => {
        startGetting('get', `api/cards/${params.id}/results`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        {getting.active ?
            <Loader />
            :
            !pageData ?
                <Error text={getting.error} />
                :
                pageData?.results?.length === 1 ?
                    <Navigate to={`/results/${pageData.results[0].id}`} replace={true} />
                    :
                    <div className="width-limit">
                        <h1>Results</h1>

                        <div className="btn-panel mb-0">
                            <Link to={`/dictionaries/${pageData.card.dictionaryId}`}
                                className="btn btn-outline-primary btn-sm">
                                Dictionary {pageData.card.dictionaryName}
                            </Link>

                            <Link to={`/cards/${pageData.card.id}`}
                                className="btn btn-outline-primary btn-sm">
                                Edit card
                            </Link>

                            <Link to={`/cards/${pageData.card.id}/similar`}
                                className="btn btn-outline-primary btn-sm">
                                Similar
                            </Link>
                        </div>

                        <Form className="form-large width-limit mb-3">
                            <Form.Group>
                                <Form.Label>Task phrase</Form.Label>
                                <div className="form-control disabled text-start">{pageData.card.challenge}</div>
                            </Form.Group>

                            {pageData.card.challengeAux &&
                                <Form.Group>
                                    <Form.Label>Additional task phrase</Form.Label>
                                    <div className="form-control disabled text-start">{pageData.card.challengeAux ?? ' '}</div>
                                </Form.Group>
                            }

                            <Form.Group>
                                <Form.Label>Answer</Form.Label>
                                <div className="form-control disabled text-start">{pageData.card.answer}</div>
                            </Form.Group>

                            {pageData.card.example &&
                                <Form.Group>
                                    <Form.Label>Example</Form.Label>
                                    <div className="form-control disabled text-start">{pageData.card.example ?? ' '}</div>
                                </Form.Group>
                            }

                        </Form>

                        {!pageData?.results?.length ?
                            <p className="my-3">No results found</p>
                            :
                            <>
                                <span>Found {pageData.results.length} results</span>
                                <AutoPaginationTable className="my-3"
                                    columns={columns}
                                    data={pageData.results!} />
                            </>
                        }
                    </div>

        }
    </>
}

const columns: ColumnDef<UserResult>[] = [
    {
        header: 'User',
        accessorKey: 'userName',
        cell: props => {
            return <Link to={`/user/${props.row.original.userId}`}>{props.row.original.userName}</Link>;
        }
    },
    {
        header: 'Direction',
        accessorKey: 'isDirect',
        cell: props => <span>{props.row.original.isDirect ? "direct" : "inverse"}</span>
    },
    {
        header: 'Strength',
        accessorKey: 'strength',
        cell: props => <Link to={`/results/${props.row.original.id}`}>{Math.round(props.row.original.strength! * 10000) / 10000}</Link>
    },
    {
        header: 'Reviews',
        accessorKey: 'updatedCount'
    },
    {
        header: 'Start of learning',
        accessorKey: 'firstUpdated',
        cell: props => <LocalDateTime value={props.row.original.firstUpdated} />
    },
    {
        header: 'Updated (Study)',
        accessorKey: 'interpretUpdated',
        cell: props => <LocalDateTime value={props.row.original.interpretUpdated} />
    },
    {
        header: 'Updated (Choice)',
        accessorKey: 'choiceUpdated',
        cell: props => <LocalDateTime value={props.row.original.choiceUpdated} />
    }
];
