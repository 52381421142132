import * as React from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { CountData, DictionaryBulkPage } from '../../types/Common';
import Error from '../general/Error';
import Loader from '../general/Loader';
import ProgressButton from '../general/ProgressButton';
import TabTextarea from '../general/TabTextarea';
import DicPanel from './DicPanel';

export default function BulkEdit() {
    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = React.useState<DictionaryBulkPage>()

    const [getting, startGetting] = useFetchOperation(onGettingSuccess, undefined, true);
    const [editing, startEditing, resetEditing] = useFetchOperation(editingSuccess);

    React.useEffect(() => {
        startGetting('get', `api/dictionaries/bulk/${params.id}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onGettingSuccess(content: DictionaryBulkPage) {
        setData(content);
        document.title = `Bulk edit cards - ${content.dictionary.name} - Dictionaries - ${(document as any).rootTitle}`;
    }

    function cardsContentChanged(content: string) {
        setData({ ...data!, content: content });
    }

    async function onEditClick() {
        const body = { id: data!.dictionary.id, content: data!.content };
        startEditing('put', `api/dictionaries/bulk`, body);
    }

    function editingSuccess(result: CountData) {
        navigate(`/dictionaries/${params.id}`, { state: { changedCount: result.count } });
    }

    return (
        <>
            <DicPanel dictionary={data?.dictionary} />
            {getting.active
                ?
                <Loader />
                :
                !data
                    ?
                    <Error text={getting.error} />
                    :
                    <div className="width-limit">
                        <h2>Bulk edit cards</h2>

                        <Form>
                            <TabTextarea text={data.content}
                                onChange={cardsContentChanged} />

                            <div className="btn-panel">
                                <ProgressButton className="btn-wide"
                                    onClick={onEditClick}
                                    active={editing.active}
                                    success={editing.success}
                                    text="Save" />
                            </div>
                        </Form>

                        {editing.error &&
                            <Alert dismissible
                                variant="danger"
                                className="my-3"
                                onClose={() => resetEditing()}>
                                {editing.error}
                            </Alert>
                        }
                    </div>
            }
        </>
    )
}
