import { Outlet } from 'react-router';
import Stat from './stat/Stat';

export default function Study() {

    return (
        <>
            <Stat />
            <Outlet />
        </>
    );
}
