import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useFetchOperation } from '../../service/Operation';
import { Card, Dictionary, DictionaryRequestParams } from '../../types/Common';
import SearchPanel from '../card/SearchPanel';
import Error from '../general/Error';
import Loader from '../general/Loader';
import ManualPaginationTable from '../general/ManualPaginationTable';
import { getApiUrlParamsPart, usePaginationAndSorting } from '../general/PaginationHelpers';
import DicPanel from './DicPanel';
import DicViewPanel from './DicViewPanel';

interface DictionaryContentPage {
    dictionary: Dictionary,
    pageCards: Card[],
    requestParams?: DictionaryRequestParams
}

export default function DicView() {
    const params = useParams();
    const navigate = useNavigate();
    const { state } = useLocation() as any;
    const [data, setData] = React.useState<DictionaryContentPage>();
    const [changedCount, setChangedCount] = React.useState<number | undefined>(state?.changedCount ?? undefined);
    const [isLimitExceeded, setIsLimitExceeded] = React.useState<boolean | undefined>(state?.isLimitExceeded);
    const { pagination, sorting, setPagination, setSorting } = usePaginationAndSorting()
    const [getting, startGetting] = useFetchOperation(onGettingSuccess, undefined, true);

    React.useEffect(() => {
        let url = `api/dictionaries/${params.id}/content`;
        const paramsPart = getApiUrlParamsPart(pagination, sorting);
        if (paramsPart)
            url += '?' + paramsPart;
        startGetting('get', url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, sorting])

    function onGettingSuccess(data: DictionaryContentPage) {
        setData(data);
        document.title = `${data.dictionary.name} - Dictionaries - ${(document as any).rootTitle}`;
    }

    function onSearch(text: string, full: boolean) {
        navigate(`/cards/search/${params.id}`, { state: { text, full } });
    }

    return (
        <>
            <DicPanel dictionary={data?.dictionary} />

            {getting.active && !data?.dictionary.id ?
                <Loader />
                :
                !data?.dictionary.id ?
                    <Error text={getting.error} />
                    :
                    <>
                        <div className="w-100 mb-0">
                            {changedCount !== undefined &&
                                <Alert dismissible
                                    variant="success"
                                    className="my-3 width-limit"
                                    onClose={() => setChangedCount(undefined)}>
                                    Changed {changedCount} cards
                                </Alert>
                            }

                            {isLimitExceeded &&
                                <Alert dismissible
                                    variant="danger"
                                    className="my-3 width-limit"
                                    onClose={() => setIsLimitExceeded(undefined)}>
                                    You can't add more than 100 cards on free account.
                                </Alert>
                            }

                            <DicViewPanel dictionary={data.dictionary}
                                className="my-3" />

                            <SearchPanel className="width-limit"
                                onSearch={onSearch} />
                        </div>

                        {getting.error ?
                            <Error text={getting.error} />
                            :
                            <>
                                {!getting.active && !data?.pageCards?.length ?
                                    <p className="my-3">No cards found</p>
                                    :
                                    <ManualPaginationTable className="my-3"
                                        columns={columns}
                                        data={data.pageCards}
                                        isDataLoading={getting.active}
                                        totalItemsCount={data.dictionary.total}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        sorting={sorting}
                                        setSorting={setSorting} />
                                }

                                {getting.active &&
                                    <Loader />
                                }
                            </>
                        }
                    </>
            }
        </>
    )
}

const columns: ColumnDef<Card>[] = [
    {
        header: '#',
        accessorKey: 'id',
        cell: props => <Link to={`/cards/${props.row.original.id}`}> {props.row.index + 1 + props.table.getState().pagination.pageIndex * props.table.getState().pagination.pageSize}</Link>
    },
    {
        header: 'Task phrase',
        accessorKey: 'challenge',
    },
    {
        header: 'Additional task phrase',
        accessorKey: 'challengeAux',
    },
    {
        header: 'Answer',
        accessorKey: 'answer',
    },
    {
        header: 'Example',
        accessorKey: 'example',
    },
];
