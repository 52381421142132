import * as React from 'react';
import { Container } from 'react-bootstrap';
import { NavMenu } from './NavMenu';

export default function Layout(props: { children: React.ReactNode }) {
    return (
        <>
            <NavMenu />
            <Container fluid className="body-container d-flex flex-column align-items-center text-center">
                {props.children}
            </Container>
        </>
    );
}

