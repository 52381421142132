import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import { ModeStat } from '../../../types/StatTypes';
import ModeStatList from './ModeStatList';

export function ChangeModeButton(props: { modeStat: ModeStat }) {
    const navigate = useNavigate();
    const modeStat = props.modeStat;
    const [show, setShow] = React.useState(false);

    function onShowClick(value: boolean) {
        setShow(value);
    }

    function modeSelected() {
        setShow(false);
        navigate('/')
    }

    return <div>
        <button className="btn btn-link lh-sm mb-1 mt-0 p-0 fs-5"
            data-bs-toggle="tooltip"
            title="Current dictionary. Click to change"
            onClick={() => onShowClick(true)}>
            {modeStat.dictionaryName}
            {!modeStat.isDirect ? ' (inverse)' : ''}
            {modeStat.isInMix ? ' (several)' : ''}
        </button>

        <Modal show={show}
            fullscreen='sm-down'
            onHide={() => onShowClick(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Switch mode
                    <Link to="studying-settings"
                        className="btn btn-outline-primary btn-sm ms-3 mb-1">
                        More settings
                    </Link>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModeStatList modeSelected={modeSelected} />
            </Modal.Body>
        </Modal>
    </div>
}
