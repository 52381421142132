import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { CardResult, } from '../../types/Common';
import { ModeStat } from '../../types/StatTypes';
import { LocalDateTime } from '../formatters/DateTimeFormat';
import AutoPaginationTable from '../general/AutoPaginationTable';
import Error from '../general/Error';
import Loader from '../general/Loader';

interface UserPage {
    id: number,
    userName: string,
    summaryStat: ModeStat[],
    lastResults: LastCardResult[],
    maxUpdatedResults: CardResult[]
}

interface ModeStatExtended extends ModeStat {
    isCurrent: boolean
}

interface LastCardResult extends CardResult {
    updatedTraining: 'C' | 'I',
    updated: string | undefined
}

const bold = 700;
export default function UserView() {
    const params = useParams();
    const [pageData, setPageData] = React.useState<UserPage>();

    const [getting, startGetting] = useFetchOperation(onGetSuccess, undefined, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const summaryStatColumns: ColumnDef<ModeStatExtended>[] = React.useMemo(() =>
        [
            {
                header: 'Dictionary name',
                accessorKey: 'dictionaryName',
                cell: props =>
                    <Link style={{ fontWeight: props.row.original.isCurrent ? bold : '' }}
                        to={`/user/${props.row.original.userId}/dictionary/${props.row.original.dictionaryId}/stat${props.row.original.isDirect ? '' : '?direct=false'}`}
                        state={{ ...props.row.original, userName: pageData?.userName }}>
                        {props.row.original.dictionaryName}{props.row.original.isDirect ? '' : ' (inv.)'}
                    </Link>
            },
            {
                header: 'Ready to review (+soon)',
                accessorKey: 'ready',
                cell: props =>
                    <Link style={{ fontWeight: props.row.original.isCurrent ? bold : '' }}
                        to={`/user/${props.row.original.userId}/dictionary/${props.row.original.dictionaryId}/history${props.row.original.isDirect ? '' : '?direct=false'}`}
                        state={{ ...props.row.original, userName: pageData?.userName }}>
                        {props.row.original.ready} (+{props.row.original.readySoon})
                    </Link>
            },
            {
                header: 'Memorized/​in studying/​not skipped/​total',
                accessorKey: 'memorizedPercent',
                cell: props =>
                    <Link style={{ fontWeight: props.row.original.isCurrent ? bold : '' }}
                        to={`/user/${props.row.original.userId}/dictionary/${props.row.original.dictionaryId}/results${props.row.original.isDirect ? '' : '?direct=false'}`}
                        state={{ ...props.row.original, userName: pageData?.userName }}>
                        ({props.row.original.memorizedPercent}%) {props.row.original.memorized}/{props.row.original.studied}/{props.row.original.notSkipped}/{props.row.original.total}
                    </Link>
            },
            {
                header: 'Total cards shown',
                accessorKey: 'updations',
                cell: props =>
                    <Link style={{ fontWeight: props.row.original.isCurrent ? bold : '' }}
                        to={`/user/${props.row.original.userId}/dictionary/${props.row.original.dictionaryId}/similar-cards${props.row.original.isDirect ? '' : '?direct=false'}`}
                        state={{ ...props.row.original, userName: pageData?.userName }}>
                        {props.row.original.reviews}
                        {(props.row.original.studied) ?
                            <> ({(props.row.original.reviews / props.row.original.studied).toFixed(1)})</>
                            :
                            <></>
                        }
                    </Link>
            },
            {
                header: 'Average strength',
                accessorKey: 'avgStrength',
                cell: props =>
                    <span style={{ fontWeight: props.row.original.isCurrent ? bold : '' }}>
                        {props.row.original.avgStrength.toFixed(4)}
                    </span>

            },
            {
                header: 'Strong+​medium+weak+on hold',
                accessorKey: 'strong',
                cell: props =>
                    <span style={{ fontWeight: props.row.original.isCurrent ? bold : '' }}>
                        {props.row.original.strong}+{props.row.original.medium}+{props.row.original.weak}({Math.round(props.row.original.weakDeltaSum)})+{props.row.original.onHold}
                    </span>
            },
            {
                header: 'Last study : choice',
                accessorKey: 'interpretShare',
                cell: props =>
                    <span style={{ fontWeight: props.row.original.isCurrent ? bold : '' }}>
                        {Math.round(props.row.original.interpretShare * 100)}%&thinsp;:&thinsp;{Math.round((1 - props.row.original.interpretShare) * 100)}%
                    </span>
            },
        ], [pageData]);

    React.useEffect(() => {
        startGetting('get', `api/user/${params.id}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    function onGetSuccess(data: UserPage) {
        if (data.lastResults) {
            data.lastResults.forEach(r => {
                const interpretUpdated = r.interpretUpdated ? r.interpretUpdated : undefined;
                const choiceUpdated = r.choiceUpdated ? r.choiceUpdated : undefined;
                const isChoiceLast = !interpretUpdated || (choiceUpdated !== undefined && choiceUpdated > interpretUpdated);
                r.updatedTraining = isChoiceLast ? 'C' : 'I'
                r.updated = isChoiceLast ? choiceUpdated : interpretUpdated;
            });
        }
        setPageData(data);
        document.title = `${data.userName} - User statistics - ${(document as any).rootTitle}`;
    }

    return (
        <>
            {getting.active ?
                <Loader />
                :
                !pageData ?
                    <Error text={getting.error} />
                    :
                    <>
                        <h1 className='mb-3'>{pageData.userName}</h1>

                        {pageData.summaryStat.length
                            ?
                            <>
                                <h2>Dictionary statistics</h2>
                                <AutoPaginationTable
                                    columns={summaryStatColumns}
                                    data={pageData.summaryStat as ModeStatExtended[]} />
                            </>
                            :
                            <p className="my-3">No results found</p>
                        }
                        {!!pageData.lastResults?.length
                            &&
                            <>
                                <h2>Last results</h2>
                                <AutoPaginationTable
                                    columns={lastResultsColumns}
                                    data={pageData.lastResults} />
                            </>
                        }
                        {!!pageData.maxUpdatedResults?.length
                            &&
                            <>
                                <h2>Cards with maximal numbers of reviews</h2>
                                <AutoPaginationTable
                                    columns={maxResultsColumns}
                                    data={pageData.maxUpdatedResults} />
                            </>
                        }
                    </>
            }
        </>
    )
}

const lastResultsColumns: ColumnDef<LastCardResult>[] = [
    {
        header: '#',
        accessorKey: 'id',
        cell: props => <Link to={`/cards/${props.row.original.card.id}`}>{props.row.index + 1}</Link>
    },
    {
        header: 'Challenge',
        accessorFn: c => c.card.challenge,
    },
    {
        header: 'Answer',
        accessorFn: c => c.card.answer,
    },
    {
        header: 'Dictionary',
        accessorKey: 'dictionaryName',
        cell: props => <Link to={`/dictionaries/${props.row.original.dictionaryId}`}>{props.row.original.dictionaryName}</Link>
    },
    {
        header: 'Strength',
        accessorKey: 'strength',
        cell: props => <Link to={`/results/${props.row.original.id}`}>{props.row.original.strength?.toFixed(4)}</Link>
    },
    {
        header: 'Reviews',
        accessorKey: 'updatedCount',
    },
    {
        header: 'Updated',
        accessorKey: 'updated',
        cell: props => <><LocalDateTime value={props.row.original.updated} /> ({props.row.original.updatedTraining})</>
    },
];

const maxResultsColumns: ColumnDef<CardResult>[] = [
    {
        header: '#',
        accessorKey: 'id',
        cell: props => <Link to={`/cards/${props.row.original.card.id}`}>{props.row.index + 1}</Link>
    },
    {
        header: 'Challenge',
        accessorFn: c => c.card.challenge,
    },
    {
        header: 'Answer',
        accessorFn: c => c.card.answer,
    },
    {
        header: 'Dictionary',
        accessorFn: c => c.dictionaryName,
        cell: props => <Link to={`/dictionaries/${props.row.original.dictionaryId}`}>{props.row.original.dictionaryName}</Link>
    },
    {
        header: 'Strength',
        accessorKey: 'strength',
        cell: props => <Link to={`/results/${props.row.original.id}`}>{props.row.original.strength?.toFixed(4)}</Link>
    },
    {
        header: 'Reviews',
        accessorKey: 'updatedCount',
    },
];
