import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CardsState {
    cardsContent: string,
    dictionaryContent: string
}

const initialState: CardsState = {
    cardsContent: '',
    dictionaryContent: ''
}

const slice = createSlice({
    name: 'adding',
    initialState,
    reducers: {
        setCardsContent: (state, action: PayloadAction<string>) => {
            state.cardsContent = action.payload;
        },

        setDictionaryContent: (state, action: PayloadAction<string>) => {
            state.dictionaryContent = action.payload;
        },
    },
})

const { setCardsContent, setDictionaryContent } = slice.actions;

export const addingReducer = slice.reducer;

export const addingActionCreators = {
    setCardsContent,
    setDictionaryContent
}
