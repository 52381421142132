import React from "react";
import { Card, DictionaryPaths } from "../../../types/Common";

export default function ExternalLinks(props: {
    card: Card,
    modeInfo: DictionaryPaths,
    isExtended?: boolean
}) {
    const { challengeLanguageCode, answerLanguageCode, reversoPath, wiktionaryLanguage } = props.modeInfo;
    const challenge = prepareChallenge(props.card.challenge, wiktionaryLanguage);;
    const wiktionaryUrl = getWiktionaryUrl(challenge, wiktionaryLanguage);
    const reversoContextUrl = `https://context.reverso.net/translation/${reversoPath}/${challenge}`;
    const googleTranslateUrl = `https://translate.google.com/?sl=${challengeLanguageCode}&tl=${answerLanguageCode}&text=${challenge}`;

    return (
        <>
            {challenge &&
                <>
                    {wiktionaryUrl &&
                        <a target="_blank" rel="noopener noreferrer"
                            className="btn  btn-sm btn-outline-primary btn-wide"
                            href={wiktionaryUrl}>
                            Wiktionary
                        </a>
                    }
                    {reversoPath &&
                        <a target="_blank" rel="noopener noreferrer"
                            className="btn  btn-sm btn-outline-primary btn-wide"
                            href={reversoContextUrl}>
                            Reverso Context
                        </a>
                    }
                    {challengeLanguageCode && answerLanguageCode && props.isExtended &&
                        <a target="_blank" rel="noopener noreferrer"
                            className="btn btn-sm btn-outline-primary btn-wide"
                            href={googleTranslateUrl}>
                            Google translate
                        </a>
                    }
                </>
            }
        </>
    );
}


export function getWiktionaryUrl(challenge: string, language: string | undefined) {
    if (language) {
        challenge = prepareChallenge(challenge, language);
        return `https://en.wiktionary.org/wiki/${challenge}` + '#' + language.substring(0, 1).toUpperCase() + language.substring(1, language.length);
    }
}

export function prepareChallenge(challenge: string, language: string | undefined,) {
    language = language?.toLowerCase();
    if (language === 'german')
        challenge = challenge.replace(/^(der|die|das) /g, '');
    if (language === 'swedish')
        challenge = challenge.replace(/^(en|ett|en\/ett) /g, '');
    else if (language === 'english')
        challenge = challenge.replace(/ (smb\/smth|smb|smth|s)/g, '');
    else if (language === 'arabic')
        challenge = challenge.replace(/(َ|ِ|ُ|ْ|ّ|ً)/g, '');
    return challenge;
}
