import { ColumnDef } from '@tanstack/react-table';
import * as moment from 'moment';
import * as React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import apiClient from '../../../service/ApiClient';
import { useFetchOperation } from '../../../service/Operation';
import { CardResultStatus, CardStatus, UserMode } from '../../../types/Common';
import { LocalDateTime } from '../../formatters/DateTimeFormat';
import getRate from '../../formatters/RateFormat';
import { TimeSpan } from '../../formatters/TimeSpanFormat';
import Error from '../../general/Error';
import Loader from '../../general/Loader';
import ManualPaginationTable from '../../general/ManualPaginationTable';
import { getApiUrlParamsPart, usePaginationAndSorting } from '../../general/PaginationHelpers';
import { UserCardsButtonPanel } from './UserCardResultsButtonPanel';
import UserPanel from './../UserPanel';

interface UserCardStatusResponse {
    skipAdded: boolean,
    skipDeleted: boolean,
    resultAdded: boolean,
    resultDeleted: boolean,
    cardResult: CardResultStatus
}
interface UserCardResultsPage {
    cardResults: CardResultStatus[],
    total: number,
    //computed:
    userMode: UserMode,
}

const statuses = new Map<CardStatus, string>([
    [CardStatus.Skipped, "Skipped"],
    [CardStatus.ToBeStudied, "Future"],
    [CardStatus.ActiveStudying, "Active"],
    [CardStatus.Preview, "In preview"],
])

const filters = {
    any: 'Any type',
    not_skipped: 'Not skipped',
    future_studying: 'Future studying',
    active_studying: 'Active studying',
    ready: 'Ready to review',
    preview: 'In preview',
    on_hold: 'On hold',
    skipped: 'Skipped',
}

type Filter = keyof typeof filters;

const defaultFilter: Filter = Object.keys(filters)[0] as Filter;

export default function UserCardResults() {
    const location = useLocation();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const userId = parseInt(params.id!);
    const dictionaryId = parseInt(params.dictionaryId!);
    const isDirect = searchParams.get('direct')?.toLowerCase() !== 'false';
    const userMode = { userId, dictionaryId, isDirect };

    let initFilter = searchParams.get("filter")?.toLowerCase() as Filter;
    if (!filters[initFilter])
        initFilter = defaultFilter;

    const [filter, setFilter] = React.useState<Filter>(initFilter as Filter);
    const [data, setData] = React.useState<UserCardResultsPage>();
    const { pagination, sorting, setPagination, setSorting } = usePaginationAndSorting();
    const [getting, startGetting] = useFetchOperation(onGetSuccess, undefined, true);

    React.useEffect(() => {
        let url = `api/user/${userId}/cards?dictionaryId=${dictionaryId}&isDirect=${isDirect}`;
        const paramsPart = getApiUrlParamsPart(pagination, sorting);
        if (filter !== defaultFilter)
            url += '&filter=' + filter;
        if (paramsPart)
            url += '&' + paramsPart;
        startGetting('get', url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, sorting, filter])

    React.useEffect(() => {
        let newFilter = searchParams.get("filter")?.toLowerCase() as Filter;
        if (!filters[newFilter])
            newFilter = defaultFilter;
        setFilter(newFilter)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    function onGetSuccess(data: UserCardResultsPage) {
        data.cardResults.forEach(cr => {
            const r = cr.result
            if (r) {
                r.isInterpretDue = !!r.interpretReviewRate && (!r.choiceReviewRate || r.interpretReviewRate < r.choiceReviewRate);
                r.isInterpretLast = !!r.interpretUpdatedInterval && (!r.choiceUpdatedInterval || moment.duration(r.interpretUpdatedInterval) < moment.duration(r.choiceUpdatedInterval));

                r.isChoiceDue = !!r.choiceReviewRate && (!r.interpretReviewRate || r.choiceReviewRate < r.interpretReviewRate);
                r.isChoiceLast = !!r.choiceUpdatedInterval && (!r.interpretUpdatedInterval || moment.duration(r.choiceUpdatedInterval) < moment.duration(r.interpretUpdatedInterval));

                cr.userMode = data.userMode;
            }
        });
        setData(data);

        setData(data);
        document.title = `${data.userMode.userName} - ${data.userMode.dictionaryName} ${data.userMode.isDirect ? '' : '(inverse) '}- Cards for studying - ${(document as any).rootTitle}`;
    }

    function onFilterChange(filter: Filter) {
        setFilter(filter);

        let sp = Array.from(searchParams).filter(([key, value]) => ['dictionary', 'direct'].includes(key));
        if (filter !== defaultFilter)
            sp.push(['filter', filter]);
        sp = sp.concat(Array.from(searchParams).filter(([key, value]) => ['sort', 'dir'].includes(key)));
        setSearchParams(sp);
    }

    function onUnskipData() {
        setData({ ...data!, cardResults: data!.cardResults.map(x => ({ ...x, isSkipped: false })) });
    }

    function onDeleteData() {
        setData({ ...data!, cardResults: data!.cardResults.map(x => ({ ...x, strength: undefined, resultId: undefined })) });
    }

    // When our cell renderer calls updateData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateData = (rowIndex: number, columnId: number, status: CardStatus, original: CardResultStatus) => {
        setData((old: UserCardResultsPage | undefined) => ({
            ...old!,
            cardResults: old!.cardResults.map((row: CardResultStatus, index: number) => {
                return (index !== rowIndex)
                    ? row
                    : {
                        ...old!.cardResults[rowIndex],
                        [columnId]: status,
                        result: undefined,
                    }
            })
        })
        );
        const body = { userId, cardId: original.card.id, isDirect, status };
        apiClient.post('api/user/card-status', body);
        // todo: find way to update the row using response
    }

    function StatusDropdown(props: any) {
        const {
            getValue,
            row: { index, original },
            column: { id },
        } = props;

        const onChange = (newVaule: CardStatus) => updateData(index, id, newVaule, original);
        var value = getValue() as CardStatus;

        return <Dropdown>
            <Dropdown.Toggle
                variant="link"
                className={value === CardStatus.Skipped ? 'text-secondary' : ''}>
                {statuses.get(value)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item as={Button}
                    variant="outline"
                    key={CardStatus.ActiveStudying}
                    active={value === CardStatus.ActiveStudying}
                    onClick={() => onChange(CardStatus.ActiveStudying)}>
                    {statuses.get(CardStatus.ActiveStudying)}
                </Dropdown.Item>


                <Dropdown.Item as={Button}
                    variant="outline"
                    key={CardStatus.ToBeStudied}
                    active={value === CardStatus.ToBeStudied}
                    onClick={() => onChange(CardStatus.ToBeStudied)}>
                    {statuses.get(CardStatus.ToBeStudied)}
                </Dropdown.Item>

                {value === CardStatus.Preview &&
                    <Dropdown.Item as={Button}
                        variant="outline"
                        key={CardStatus.Preview}
                        active={value === CardStatus.Preview}
                        onClick={() => onChange(CardStatus.Preview)}>
                        {statuses.get(CardStatus.Preview)}
                    </Dropdown.Item>
                }

                <Dropdown.Item as={Button}
                    variant="outline"
                    key={CardStatus.Skipped}
                    active={value === CardStatus.Skipped}
                    onClick={() => onChange(CardStatus.Skipped)}>
                    {statuses.get(CardStatus.Skipped)}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    }

    function onGetRowClass(card: CardResultStatus) {
        if (card.status === CardStatus.Skipped)
            return 'skipped-card';
    }

    const columns: ColumnDef<CardResultStatus>[] = React.useMemo(() => [
        {
            header: '#',
            accessorKey: 'id',
            accessorFn: x => x.card.id,
            cell: props => <Link to={`/cards/${props.row.original.card.id}`}>{props.row.index + 1 + props.table.getState().pagination.pageIndex * props.table.getState().pagination.pageSize}</Link>
        },
        {
            header: 'Challenge',
            accessorKey: 'challenge',
            accessorFn: x => x.card.challenge,
            cell: props => <span>{props.row.original.card.challenge} {props.row.original.card.challengeAux ? <span className="text-secondary">{props.row.original.card.challengeAux}</span> : ''}</span>
        },
        {
            header: 'Answer',
            accessorKey: 'answer',
            accessorFn: x => x.card.answer,
            cell: props => <>{props.row.original.card.answer}{props.row.original.card.example ? <span className="text-secondary"> (e)</span> : ''}</>
        },
        {
            header: 'Studying status',
            accessorKey: 'status',
            cell: StatusDropdown
        },
        {
            header: 'Strength',
            accessorKey: 'strength',
            accessorFn: x => x.result?.strength,
            cell: props =>
                <>
                    <Link to={`/results/${props.row.original.result?.id}`}
                        state={props.row.original.userMode}>{props.row.original.result?.strength.toFixed(4)}</  Link>
                    <br />
                    <span className={!props.row.original.result?.isStrengthValid ? "fw-bold" : ""}>
                        <TimeSpan value={props.row.original.result?.interpretReviewInterval} />
                        <br />
                        <TimeSpan value={props.row.original.result?.choiceReviewInterval} />
                    </span>
                </>
        },
        {
            header: 'Reviews',
            accessorKey: 'updated_count',
            accessorFn: x => x.result?.updatedCount,
            cell: props => <>{props.row.original.result?.updatedCount}</>
        },
        {
            header: 'Start of learning',
            accessorKey: 'first_updated',
            accessorFn: x => x.result?.firstUpdated,
            cell: props => <LocalDateTime value={props.row.original.result?.firstUpdated} />
        },
        {
            header: 'Updated (study)',
            accessorKey: 'interpret_updated',
            accessorFn: x => x.result?.interpretUpdated,
            cell: props =>
                <>
                    <LocalDateTime value={props.row.original.result?.interpretUpdated} />
                    <br />
                    <span className={props.row.original.result?.isInterpretLast ? "fw-bold" : ""}><TimeSpan value={props.row.original.result?.interpretUpdatedInterval} /></span>
                </>
        },
        {
            header: 'Study rate',
            accessorKey: 'interpret_review_rate',
            accessorFn: x => x.result?.interpretReviewRate,
            cell: props => <span className={props.row.original.result?.isInterpretDue ? 'fw-bold' : ''}>{getRate(props.row.original.result?.interpretReviewRate)} </span>
        },
        {
            header: 'Updated (choice)',
            accessorKey: 'choice_updated',
            accessorFn: x => x.result?.choiceUpdated,
            cell: props =>
                <>
                    <LocalDateTime value={props.row.original.result?.choiceUpdated} />
                    <br />
                    <span className={props.row.original.result?.isChoiceLast ? "fw-bold" : ""}><TimeSpan value={props.row.original.result?.choiceUpdatedInterval} />
                    </span>
                </>
        },
        {
            header: 'Choice rate',
            accessorKey: 'choice_review_rate',
            accessorFn: x => x.result?.choiceReviewRate,
            cell: props => <span className={props.row.original.result?.isChoiceDue ? 'fw-bold' : ''}>{getRate(props.row.original.result?.choiceReviewRate)} </span>
        },
        {
            header: 'Ready date',
            accessorKey: 'ready_date',
            cell: props =>
                <>
                    <LocalDateTime value={props.row.original.result?.ready} />
                    <br />
                    <span className={moment.duration(props.row.original.result?.readyInterval).asDays() < 0 ? "fw-bold" : ""}><TimeSpan value={props.row.original.result?.readyInterval} />
                    </span>
                </>
        },
    ], [])

    return (
        <>
            <UserPanel userMode={data?.userMode} />

            {data?.userMode &&
                <div>
                    <Dropdown
                        className="mt-3 d-inline-block">

                        <Dropdown.Toggle
                            className="btn-wide" >
                            {filters[filter]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {Object.entries(filters).map(([key, value]) =>
                                <Dropdown.Item as={Button}
                                    variant="outline"
                                    key={key}
                                    active={key === filter}
                                    onClick={() => onFilterChange(key as Filter)}>
                                    {value}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>

                    <span className="ms-2">{data.total} cards</span>
                </div>
            }

            {getting.error ?
                <Error text={getting.error} />
                :
                <>
                    {data?.cardResults?.length ?
                        <div className="w-100">
                            <ManualPaginationTable className="mt-3 mb-3"
                                columns={columns}
                                data={data.cardResults}
                                isDataLoading={getting.active}
                                totalItemsCount={data.total}
                                pagination={pagination}
                                setPagination={setPagination}
                                sorting={sorting}
                                setSorting={setSorting}
                                getRowClassName={onGetRowClass} />

                            {!getting.active &&
                                <UserCardsButtonPanel userMode={userMode}
                                    unskipData={onUnskipData}
                                    deleteData={onDeleteData} />
                            }
                        </div>
                        :
                        !getting.active &&
                        <p className="my-3">No cards found</p>
                    }

                    {getting.active &&
                        <Loader />
                    }
                </>
            }
        </>
    )
}

