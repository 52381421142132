import { ChangeEvent } from "react";

export default function useOnChange<T>(
    obj: T | undefined,
    objSetter: (v: T) => void
) {
    const onStringChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => onPropertyChange(event, 'string');

    const onNumberChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => onPropertyChange(event, 'number');

    const onBooleanChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => onPropertyChange(event, 'boolean');

    function onPropertyChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, type: string) {
        const name = event.target.id as keyof T;
        const strValue = event.target.value;

        let value: string | number | boolean | undefined = strValue;
        if (type === 'boolean') {
            if (event.target.type === 'checkbox')
                value = (event.target as HTMLInputElement).checked;
            else
                value = strValue === 'true';
        }
        else if (type === 'number') {
            const num = Number(strValue);
            if (!isNaN(num) && num.toString() === strValue)
                value = num;
            else if (!strValue)
                value = undefined;
        }
        if (obj)
            objSetter({ ...obj, [name]: value });
    }

    return { onStringChange, onNumberChange, onBooleanChange }
}
