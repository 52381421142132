import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '.';
import apiClient from '../service/ApiClient';
import { Mode } from '../types/Common';
import { statActionCreators } from './Stat';

export interface StatState {
    isModeChangingActive: boolean,
}

const initialState: StatState = {
    isModeChangingActive: false,
}

const slice = createSlice({
    name: 'mode',
    initialState,
    reducers: {
        changeStart: state => {
            state.isModeChangingActive = true;
        },

        changeSuccess: (state, action: PayloadAction<Mode>) => {
            state.isModeChangingActive = false;
        },

        changeFail: state => {
            state.isModeChangingActive = false;
        },
    },
})

const { changeStart, changeSuccess, changeFail } = slice.actions;

const changeMode = (dictionaryId: number, isDirect: boolean) => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(changeStart());
    apiClient.post('/api/study/settings/mode', { dictionaryId, isDirect })
        .then(() => {
            dispatch(changeSuccess({ dictionaryId, isDirect }));
            dispatch(statActionCreators.getModeStat());
        })
        .catch(() => {
            dispatch(changeFail())
        });
}

export const modeReducer = slice.reducer;

export const modeActionCreators = {
    changeMode,
    changeSuccess
}
