import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useFetchOperation } from '../../service/Operation';
import { SimilarCards, UserMode } from '../../types/Common';
import Error from '../general/Error';
import Loader from '../general/Loader';
import ManualPaginationTable from '../general/ManualPaginationTable';
import { getApiUrlParamsPart, usePaginationAndSorting } from '../general/PaginationHelpers';
import UserPanel from './UserPanel';
import { Button, Dropdown } from 'react-bootstrap';

interface UserSimilarCardsPage {
    relations: LocalSimilarCards[],
    total: number,
    userMode: UserMode
}

interface LocalSimilarCards extends SimilarCards {
    userMode: UserMode
}

const defaultFilter: Filter = 'withSimilar';

type Filter = 'withSimilar' | 'all';

const filters: [Filter, string][] = [
    ['withSimilar', 'With similar'],
    ['all', 'All'],
];

export default function UserSimilarCards() {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const userId = parseInt(params.id!);
    const dictionaryId = parseInt(params.dictionaryId!);
    const isDirect = searchParams.get('direct')?.toLowerCase() !== 'false';
    const [data, setData] = React.useState<UserSimilarCardsPage>();
    const { pagination, sorting, setPagination, setSorting } = usePaginationAndSorting();
    const [getting, startGetting] = useFetchOperation(onGetSuccess, undefined, true);

    let initFilter = searchParams.get("filter")?.toLowerCase();
    if (!filters.find(x => x[0] === (initFilter ?? '')))
        initFilter = defaultFilter;

    const [filter, setFilter] = React.useState<Filter>(initFilter as Filter);

    React.useEffect(() => {
        let url = `api/user/${userId}/similar-cards?dictionaryId=${dictionaryId}&isDirect=${isDirect}`;
        const paramsPart = getApiUrlParamsPart(pagination, sorting);
        if (filter !== defaultFilter)
            url += '&filter=' + filter;
        if (paramsPart)
            url += '&' + paramsPart;
        startGetting('get', url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, sorting, filter])


    function onGetSuccess(data: UserSimilarCardsPage) {
        data.relations?.forEach(relation => {
            relation.similar?.forEach(card => {
                if (card.dictionaryId === data.userMode.dictionaryId)
                    card.dictionaryName = '';
            });
            relation.userMode = data.userMode;
        });
        setData(data);
        document.title = `${data.userMode.userName} - ${data.userMode.dictionaryName} ${data.userMode.isDirect ? '' : '(inverse) '}- Cards for studying - ${(document as any).rootTitle}`;
    }

    function onFilterChange(filter: Filter) {
        setFilter(filter);

        let sp = Array.from(searchParams).filter(([key, value]) => ['dictionary', 'direct'].includes(key));
        if (filter !== defaultFilter)
            sp.push(['filter', filter]);
        sp = sp.concat(Array.from(searchParams).filter(([key, value]) => ['sort', 'dir'].includes(key)));
        setSearchParams(sp);
    }

    return (
        <>
            <UserPanel userMode={data?.userMode} />

            {getting.error ?
                <Error text={getting.error} />
                :
                <>
                    {data?.relations?.length ?
                        <div className="w-100">
                            <div>
                                <Dropdown
                                    className="mt-3 d-inline-block">

                                    <Dropdown.Toggle
                                        className="btn-wide" >
                                        {filters.find(x => x[0] === filter)?.[1]}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {filters.map(([key, value]) =>
                                            <Dropdown.Item as={Button}
                                                variant="outline"
                                                key={key}
                                                active={key === filter}
                                                onClick={() => onFilterChange(key)}>
                                                {value}
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <span className="ms-2">{data.total} cards</span>
                            </div>

                            <ManualPaginationTable className="my-3"
                                columns={columns}
                                data={data.relations}
                                totalItemsCount={data.total}
                                isDataLoading={getting.active}
                                pagination={pagination}
                                setPagination={setPagination}
                                sorting={sorting}
                                setSorting={setSorting} />
                        </div>
                        :
                        !getting.active &&
                        <p className="my-3">No similar cards found</p>
                    }

                    {getting.active &&
                        <Loader />
                    }
                </>
            }
        </>
    )
}

const columns: ColumnDef<SimilarCards>[] = [
    {
        header: '#',
        accessorKey: 'id',
        cell: (props) => <Link to={`/cards/${props.row.original.id}/similar`}
            state={props.row.original.userMode}>{props.row.index + 1 + props.table.getState().pagination.pageIndex * props.table.getState().pagination.pageSize}</Link>
    },
    {
        header: 'Challenge',
        accessorKey: 'challenge',
    },
    {
        header: 'Answer',
        accessorKey: 'answer',
    },
    {
        header: 'Similar',
        accessorKey: 'similar',
        cell: props =>
            <>
                {props.row.original.similar?.map(x =>
                    <span key={x.id}>{x.challenge} {x.dictionaryName ? <span> ({x.dictionaryName})</span> : <></>} - <span className="text-secondary">{x.answer}</span><br /></span>)
                }
            </>
    },
]
