import * as moment from 'moment';

export function TimeSpan(props: { value: string | undefined }) {
    return <>{getTimeSpan(props.value)}</>
}

export function getTimeSpan(value: string | undefined) {
    if (value) {
        let d = moment.duration(value);
        const isNegative = d.asYears() < 0
        if (isNegative)
            d = d.abs();

        let f;
        if (d.asYears() >= 1)
            f = `${d.years()}y ${Math.floor(d.asDays() - d.years() * 365.25)}d`;
        else if (d.asDays() >= 1)
            f = `${Math.floor(d.asDays())}d ${d.hours()}h`;
        else if (d.asHours() >= 1)
            f = `${d.hours()}h ${d.minutes()}m`;
        else if (d.asMinutes() >= 1)
            f = `${d.minutes()}m ${d.seconds()}s`;
        else
            f = `${d.seconds()}s`;

        if (isNegative)
            f = '-' + f;
        return f;
    }
    return undefined;
}