import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import AutoPaginationTable from '../general/AutoPaginationTable';
import Error from '../general/Error';
import Loader from '../general/Loader';

interface DictionarySummary {
    id: number,
    name: string,
    total: number,
    languages: string,
    authorName: string,
    authorId: number
}

export default function DicList() {
    const [dictionaries, setDictionaries] = React.useState<DictionarySummary[]>();

    const [getting, startGetting] = useFetchOperation(setDictionaries, undefined, true);

    React.useEffect(() => {
        startGetting('get', `api/dictionaries`);
        document.title = `Dictionaries - ${(document as any).rootTitle}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {getting.active ?
                <Loader />
                :
                getting.error ?
                    <Error text={getting.error} />
                    :
                    !dictionaries ?
                        <p className="my-3">No dictionaries found</p>
                        :
                        <div className="w-100">
                            <h1 className='mb-3'>Dictionaries</h1>

                            <AutoPaginationTable
                                columns={columns}
                                data={dictionaries}
                                className="mb-3" />
                        </div>
            }
        </>
    )
}

const columns: ColumnDef<DictionarySummary>[] = [
    {
        header: 'Name',
        accessorKey: 'name',
        cell: props => <Link to={`/dictionaries/${props.row.original.id}`}> {props.row.original.name}</Link>
    },
    {
        header: 'Total cards',
        accessorKey: 'total',
    },
    {
        header: 'Languages',
        accessorKey: 'languages',
    },
    {
        header: 'Author',
        accessorKey: 'authorName',
        cell: props => <Link to={`/user/${props.row.original.authorId}`}> {props.row.original.authorName}</Link>
    },
];
