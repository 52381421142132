import * as React from 'react';
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store';
import LoginMenu from './../auth/LoginMenu';
import HoverDropDown from './HoverDropDown';

export function NavMenu() {

    const auth = useAppSelector(state => state.auth);
    const [isExpanded, setIsExpanded] = React.useState(false);

    function setNavExpanded(expanded: boolean) {
        setIsExpanded(expanded);
    }

    function closeNav() {
        setIsExpanded(false);
    }

    return (
        <Navbar collapseOnSelect
            expand="sm"
            bg="primary"
            variant="dark"
            className="box-shadow py-1"
            onToggle={setNavExpanded}
            expanded={isExpanded} >

            <Container fluid
                className="px-2">
                <Navbar.Brand
                    as={Link}
                    to="/"
                    onClick={closeNav}
                    className="me-0">
                    Improve Your Vocabulary
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav"
                    className="ms-1" />

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto">

                        <HoverDropDown titleText="Dictionaries"
                            titleLinkTo="/dictionaries"
                            className="d-none d-sm-block">
                            <NavDropdown.Item as={Link} to="/dictionaries">All dictionaries</NavDropdown.Item>
                            {auth.user && <>
                                <NavDropdown.Item as={Link} to="/dictionaries/add">Add dictionary</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/cards/add">Add cards</NavDropdown.Item>
                            </>}
                            {auth.user &&
                                <NavDropdown.Item as={Link} to="/languages">Languages</NavDropdown.Item>}
                            {auth.isModerator &&
                                <NavDropdown.Item as={Link} to="/cards/marked">Marked cards</NavDropdown.Item>}
                            {auth.isUser &&
                                <NavDropdown.Item as={Link} to="/cards/search">Search cards</NavDropdown.Item>}
                        </HoverDropDown>

                        <LoginMenu closeNav={closeNav} />

                        <Nav.Link as={Link} to="/dictionaries" className="d-block d-sm-none" onClick={closeNav}>Dictionaries</Nav.Link>
                        {auth.user && <>
                            <Nav.Link as={Link} to="/dictionaries/add" className="d-block d-sm-none ms-3" onClick={closeNav}>Add dictionary</Nav.Link>
                            <Nav.Link as={Link} to="/cards/add" className="d-block d-sm-none ms-3" onClick={closeNav}>Add cards</Nav.Link>
                        </>}
                        {auth.user &&
                            <Nav.Link as={Link} to="/languages" className="d-block d-sm-none ms-3" onClick={closeNav}>Languages</Nav.Link>}
                        {auth.isModerator &&
                            <Nav.Link as={Link} to="/cards/marked" className="d-block d-sm-none ms-3" onClick={closeNav}>Marked cards</Nav.Link>}
                        {auth.isUser &&
                            <Nav.Link as={Link} to="/cards/search" className="d-block d-sm-none ms-3" onClick={closeNav}>Search cards</Nav.Link>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
