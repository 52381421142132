import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '.';
import { SimilarCardSlim, TaskKind, TrainingCard, TrainingCardData, TrainingType } from '../types/TrainingTypes';
import { trainingActionCreators } from './Training';

interface InterpretState {
    task: string | undefined,
    taskAux: string | undefined,
    solution: string | undefined,
    maskedSolution: string | undefined,
    solutionAux: string | undefined,
    maskedSolutionAux: string | undefined,
    example: string | undefined,
    isExampleShown: boolean,
    similar: SimilarCardSlim[] | undefined,
    symbolsShownCount: number,
}

const initialState: InterpretState = {
    task: ' ',
    taskAux: '',
    solution: '',
    maskedSolution: ' ',
    solutionAux: '',
    maskedSolutionAux: '',
    example: '',
    isExampleShown: false,
    similar: undefined,
    symbolsShownCount: 0,
}

const slice = createSlice({
    name: 'interpret',
    initialState,
    reducers: {
        setNextCard: (state, action: PayloadAction<TrainingCard>) => {
            const card = action.payload;
            state.symbolsShownCount = 0;
            state.isExampleShown = false;
            updateCardParts(state, card, false);
            updateUserChoice(state, card, false)
        },

        showHint: (state, action: PayloadAction<{ card: TrainingCard, symbolsShownCount: number }>) => {
            const { card, symbolsShownCount } = action.payload;
            state.symbolsShownCount = symbolsShownCount;
            updateCardParts(state, card!, false);
            updateUserChoice(state, card!, false);
        },

        showExample: state => {
            state.isExampleShown = true;
        },

        showSolution: (state, action: PayloadAction<TrainingCard>) => {
            const card = action.payload;
            state.isExampleShown = true;
            updateCardParts(state, card, true);
            updateUserChoice(state, card, true);
        },
    },

    extraReducers: (builder) => {
        builder.addCase('training/reset', (state, action) => {
            return initialState;
        });

        builder.addCase('training/refreshCardData', (state, action) => {
            const data = (action as PayloadAction<TrainingCardData>).payload;
            if (data.card?.trainingType === TrainingType.Interpret) {
                updateCardParts(state, data.card!, data.isSolutionShown);
                updateUserChoice(state, data.card!, data.isSolutionShown);
            }
        });
    }
});

function updateCardParts(state: InterpretState, card: TrainingCard, isSolutionShown: boolean) {
    const ts = getCardParts(card, isSolutionShown);
    state.task = ts.task;
    state.solution = ts.solution.replace(/;;/gi, '\r\n').replace(/\r\n;/gi, ';\r\n');
    state.taskAux = ts.taskAux;
    state.solutionAux = ts.solutionAux?.replace(/;;/gi, '\r\n').replace(/\r\n;/gi, ';\r\n');
}

function updateUserChoice(state: InterpretState, card: TrainingCard, isSolutionShown: boolean) {
    const { solution, solutionAux } = state;
    if (isSolutionShown)
        state.symbolsShownCount = solution!.length;

    state.maskedSolution = state.symbolsShownCount > 0 ? solution!.substring(0, state.symbolsShownCount) + solution!.substring(state.symbolsShownCount).replace(/./gi, '_') : ' ';

    state.maskedSolutionAux = isSolutionShown ? solutionAux : '';
    state.example = card?.example?.replace(/([;?!.]);/gi, '$1\r\n');
    state.similar = isSolutionShown ? card!.similar : undefined;
}

function getCardParts(card: TrainingCard, isSolutionShown: boolean) {
    const task = card ? (card.modeInfo.isDirect ? card.challenge : card.answer) : ' ';
    const tAux = card ? (card.modeInfo.isDirect ? card.challengeAux : '') : '';
    const solution = card ? (card.modeInfo.isDirect ? card.answer : card.challenge) : '';
    const sAux = card ? (card.modeInfo.isDirect ? '' : card.challengeAux) : '';

    var taskKind = card.modeInfo.modeSetting.taskKind;
    if (taskKind === TaskKind.AuxOnly && !card.modeInfo.onlyChallengeAuxAvailable)
        taskKind = TaskKind.BothAlways;

    const both = taskKind === TaskKind.BothAlways
        || taskKind === TaskKind.BothOnCombinedAndInterpret
        || taskKind === TaskKind.BothOnInterpret
        || (taskKind === TaskKind.BothOnRevealed && isSolutionShown);
    const auxOnly = taskKind === TaskKind.AuxOnly;

    return {
        task: auxOnly && tAux ? tAux : task,
        solution: auxOnly && sAux ? sAux : solution,
        taskAux: both ? tAux : (isSolutionShown && auxOnly && tAux ? task : ''),
        solutionAux: both ? sAux : '',
    }
}

const setNextCard = (card: TrainingCard) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(slice.actions.setNextCard(card));
    }
}

const showHint = () => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState().interpret;
        const training = getState().training;

        const ts = getCardParts(training.card!, false);
        const symbolsShownCount = getHintSymbolsShownCount(state.symbolsShownCount, ts.solution);
        if (symbolsShownCount === ts.solution.length)
            dispatch(showSolution());
        else {
            dispatch(slice.actions.showHint({
                card: training.card!,
                symbolsShownCount,
            }));
        }
    }
};

function getHintSymbolsShownCount(symbolsShownCount: number, solution: string) {
    symbolsShownCount++;

    if (symbolsShownCount < solution.length) {
        const match = /^[ .,?!:;[]()-]+/.exec(solution.substring(symbolsShownCount));
        if (match)
            symbolsShownCount += match[0].length;
    }
    else
        symbolsShownCount = solution.length;
    return symbolsShownCount;
}

const showSolution = () => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const training = getState().training;

        dispatch(trainingActionCreators.showSolution(false));
        dispatch(slice.actions.showSolution(training.card!));
    }
};

export const interpretReducer = slice.reducer;

export const interpretActionCreators = {
    setNextCard,
    showHint,
    showExample: slice.actions.showExample,
    showSolution,
}
