import React from 'react';
import { Alert, Form, FormGroup } from 'react-bootstrap';
import GoogleButton from 'react-google-button';
import { useNavigate } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { useAppDispatch } from '../../store';
import { authActionCreators } from '../../store/Auth';
import ProgressButton from '../general/ProgressButton';

export default function SignIn() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [login, startLogin, resetLogin] = useFetchOperation(onLoginSuccess);

    const [model, setModel] = React.useState({
        username: '',
        password: '',
    });

    React.useEffect(() => {
        document.title = `Sign in - ${(document as any).rootTitle}`;
    }, []);

    const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.id;
        const value = event.target.value;
        setModel({ ...model, [name]: value });
    }

    function onHandleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        startLogin('post', '/api/auth/login', model)
    }

    function onLoginSuccess(data: { token: string }) {
        dispatch(authActionCreators.setToken(data.token));
        navigate('/');
    }

    function signInWithGoogle() {
        window.location.href = getSignInUrl();
    }

    return (
        <div className="width-narrow m-auto pb-5">
            <h1>Sign in</h1>

            <GoogleButton onClick={signInWithGoogle}
                className="mx-auto my-4" />

            <p>Or use your login and password</p>

            <Form onSubmit={onHandleSubmit}>

                <FormGroup>
                    <Form.Label htmlFor="username">Login</Form.Label>
                    <Form.Control
                        id="username"
                        value={model.username}
                        onChange={onFieldChange} />
                </FormGroup>

                <FormGroup>
                    <Form.Label htmlFor="password">Password</Form.Label>
                    <Form.Control
                        type="password"
                        id="password"
                        value={model.password}
                        onChange={onFieldChange} />
                </FormGroup>

                <ProgressButton type="submit"
                    className="mt-4 mb-2 d-block mx-auto btn-wide"
                    disabled={!model.username  || !model.password} 
                    active={login.active}
                    success={login.success}
                    text="Sign in" />

                {login.error &&
                    <Alert dismissible
                        variant="danger"
                        className="my-3"
                        onClose={resetLogin}>
                        {login.error}
                    </Alert>
                }
            </Form>
        </div>
    );
}

function getSignInUrl() {
    let location = window.location.href;
    const ind = location.lastIndexOf('/');
    if (ind !== -1)
        location = location.substring(0, ind);
    if (!location.endsWith('/'))
        location += '/';
    return `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${location}signin-google&client_id=244864742183-cc46gnbnk9ltra38ed8k12o31ufrc9ft.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile`;
}
