import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '.';
import apiClient from '../service/ApiClient';
import { DictionarySlim } from '../types/Common';

export interface DictionariesState {
    isLoading: boolean,
    dictionaries: DictionarySlim[] | undefined,
    dictionaryId: number
}

const initialState: DictionariesState = {
    isLoading: false,
    dictionaries: [{ id: 2, name: 'en_words' }],
    dictionaryId: 2
}

const slice = createSlice({
    name: 'dictionaries',
    initialState,
    reducers: {
        start: (state) => {
            state.isLoading = true;
        },
        success: (state, action: PayloadAction<DictionarySlim[]>) => {
            state.isLoading = false;
            state.dictionaries = action.payload;
        },
        fail: state => {
            state.isLoading = false;
        },
        select: (state, action: PayloadAction<number>) => {
            state.dictionaryId = action.payload;
        },
    }
})

const { start, success, fail, select } = slice.actions;

const getDictionaries = () => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(start());
    apiClient.get('/api/dictionaries/slim')
        .then((data: DictionarySlim[]) => {
            dispatch(success(data));
        })
        .catch(() => {
            dispatch(fail())
        });
}

export const dictionariesReducer = slice.reducer;

const dictionaries = {
    getDictionaries,
    select: select
};

export default dictionaries;
