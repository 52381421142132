import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link, useParams } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { Language, LanguageData } from '../../types/Common';
import Alerts from '../general/Alerts';
import Error from '../general/Error';
import Loader from '../general/Loader';
import ProgressButton from '../general/ProgressButton';
import LangEditor from './LangEditor';

export default function LangEdit() {
    const params = useParams();

    const [language, setLanguage] = React.useState<Language>({} as Language);
    const [deleted, setDeleted] = React.useState(false);
    const [modalShow, setModalShow] = React.useState(false);

    const [getting, startGetting] = useFetchOperation(onGettingSuccess, undefined, true);
    const [editing, startEditing, resetEditing] = useFetchOperation(() => { setTimeout(resetEditing, 2000) });
    const [deleting, startDeleting, resetDeleting] = useFetchOperation(() => setDeleted(true));

    React.useEffect(() => {
        startGetting('get', `api/languages/${params.id}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onGettingSuccess(data: Language) {
        setLanguage(data)
        document.title = `Edit language - ${(document as any).rootTitle}`;
    }

    function langEditorOnChange(val: LanguageData) {
        setLanguage({ ...language, ...val });
    }

    function onEditClick() {
        startEditing('put', `api/languages`, language);
        resetDeleting();
    }

    function onDeleteClick() {
        setModalShow(false)
        startDeleting('delete', `api/languages/${params.id}`);
        resetEditing();
    }

    return (
        <>
            {getting.active ?
                <Loader />
                :
                !language.id ?
                    <Error text={getting.error} />
                    :
                    <div className="width-limit">
                        <h1>Edit Language</h1>

                        <Form className="form-large">
                            <LangEditor language={language}
                                onChange={langEditorOnChange} />

                            <div className="btn-panel">
                                <ProgressButton className="btn-wide"
                                    onClick={onEditClick}
                                    disabled={deleting.active || deleted}
                                    active={editing.active}
                                    success={editing.success} />

                                <ProgressButton variant="outline-danger"
                                    className="btn-wide"
                                    onClick={() => setModalShow(true)}
                                    disabled={deleted}
                                    active={deleting.active}
                                    success={deleting.success}
                                    text="Delete"
                                    successText="Deleted" />
                            </div>
                        </Form>

                        <Modal show={modalShow}
                            onHide={() => setModalShow(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Danger!</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <p className="fw-bold">Are you sure you want to delete language '{language.name}?'</p>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary"
                                    onClick={() => setModalShow(false)}>Cancel</Button>
                                <Button variant="danger"
                                    onClick={onDeleteClick}>
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Alerts error={editing.error}
                            onClose={() => resetEditing()} />

                        <Alerts success={deleting.success}
                            error={deleting.error}
                            onClose={() => resetDeleting()}
                            successContent={
                                <>
                                    <>The language was successfully deleted. </>
                                    <Link to='/languages' >Return to the list</Link>
                                </>} />

                    </div>
            }
        </>
    )
}
