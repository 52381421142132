import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '.';
import apiClient from '../service/ApiClient';

const tokenStorageKey = 'token';

export interface AuthState {
    user: AuthUser | undefined
    isUser: boolean,
    isCreator: boolean,
    isModerator: boolean,
    isAdmin: boolean
}

export interface AuthUser {
    token: string,
    id: number,
    name: string,
    roles: string[]
}

interface TokenData {
    nameid: number,
    name: string,
    role: string[],
    exp: number,
    nbf: number
}

const initialState: AuthState = {
    user: undefined,
    isUser: false,
    isCreator: false,
    isModerator: false,
    isAdmin: false
}

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<AuthUser>) => {
            state.user = action.payload;
            state.isUser = !!action.payload;
            state.isCreator = action.payload.roles?.includes('creator');
            state.isModerator = action.payload.roles?.includes('moderator');
            state.isAdmin = action.payload.roles?.includes('admin');
        },

        logout: () => {
            return initialState;
        }
    }
})

function initialize() {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const token = localStorage.getItem(tokenStorageKey);
        if (token) {
            const tokenData: TokenData = JSON.parse(atob(token.split('.')[1]));

            if (tokenData.exp > new Date().getTime() / 1000) {
                dispatchUser(token, dispatch);

                if ((tokenData.exp - new Date().getTime() / 1000) < (tokenData.exp - tokenData.nbf) / 2) {
                    apiClient.post('/api/auth/refresh-token').then(data => {
                        localStorage.setItem(tokenStorageKey, data.token);
                        dispatchUser(data.token, dispatch);
                    });
                }
            }
            else
                localStorage.removeItem(tokenStorageKey);
        }
    }
}

function dispatchUser(token: string, dispatch: AppDispatch) {
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    const user: AuthUser = {
        token,
        id: tokenData.nameid,
        name: tokenData.unique_name,
        roles: tokenData.role
    }
    dispatch(slice.actions.setUser(user));
}

const setToken = function (token: string) {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        localStorage.setItem(tokenStorageKey, token);
        dispatchUser(token, dispatch);
    }
}

const logout = function () {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        localStorage.removeItem(tokenStorageKey);
        dispatch(slice.actions.logout());
    }
}
export const authReducer = slice.reducer;

export const authActionCreators = {
    initialize,
    setToken,
    logout,
};
