import * as React from 'react';
import { Link } from 'react-router-dom';
import { UpdatedResult } from '../../../types/StatTypes';
import DiffSpan from '../../general/DiffSpan';
import { getWiktionaryUrl } from '../../card/edit/ExternalLinks';

export function LastResultStat(props: { lastResult: UpdatedResult; }) {
    const oldStrength = parseFloat(props.lastResult.oldStrength.toFixed(2));
    const newStrength = parseFloat(props.lastResult.newStrength.toFixed(2));
    const delta = newStrength - oldStrength;

    var wiktionaryUrl = getWiktionaryUrl(props.lastResult.challenge, props.lastResult.wiktionaryLanguage)
    return (
        <span>
            <span>| </span>
            <Link to={`/cards/${props.lastResult.cardId}`}>
                {oldStrength}
            </Link>

            {wiktionaryUrl ? < Link to={wiktionaryUrl}
                target="_blank"
                className="fw-normal">
                <DiffSpan value={delta} oldValue={0.00} addPlus={true} valuePrecision={2} />
            </Link>
                :
                <DiffSpan value={delta} oldValue={0.00} addPlus={true} valuePrecision={2} />
            }
            =
            <Link to={`/results/${props.lastResult.id}`}>
                {newStrength}
            </Link>
        </span>
    );
}
