import { ButtonGroup } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import { Dictionary } from '../../types/Common';

export default function DicPanel(props: { dictionary: Dictionary | undefined }) {
    const { state, pathname } = useLocation();
    const dictionary = props.dictionary ?? (state ? state as Dictionary : undefined);

    if (dictionary) {
        const { id, name, authorId, authorName, total, challengeLanguage, answerLanguage, reversoToLanguage } = dictionary;
        return (
            <div>
                <h1 className="text-break">Dictonary '{name}'</h1>
                <div className="my-3">
                    <span className='me-3 d-inline-block'>{total} cards</span>

                    {challengeLanguage &&
                        <span className='me-3 d-inline-block'>
                            {challengeLanguage.code}
                            {answerLanguage &&
                                <>→{answerLanguage.code}</>
                            }
                            {reversoToLanguage &&
                                <> ({reversoToLanguage.code})</>
                            }
                        </span>
                    }

                    <span className="d-inline-block">
                        <span className='me-1'>Author:</span>

                        <Link to={`/user/${authorId}`}>
                            {authorName}
                        </Link>
                    </span>
                </div>
                <ButtonGroup className="d-block">
                    <Link className={"btn " + (pathname.match(/\d$/) ? "btn-primary" : "btn-outline-primary")}
                        to={`/dictionaries/${id}`}
                        state={dictionary}>
                        Cards</Link>
                    <Link className={"btn " + (pathname.endsWith('/edit') ? "btn-primary" : "btn-outline-primary")}
                        to={`/dictionaries/${id}/edit`}
                        state={dictionary}>
                        Edit</Link>
                    <Link className={"btn " + (pathname.endsWith('bulk-edit') ? "btn-primary" : "btn-outline-primary")}
                        to={`/dictionaries/${id}/bulk-edit`}
                        state={dictionary}>
                        Bulk edit</Link>
                    {
                        pathname.endsWith('reorder') &&
                        <Link className={"btn " + (pathname.endsWith('reorder') ? "btn-primary" : "btn-outline-primary")}
                            to={`/dictionaries/${id}/reorder`}
                            state={dictionary}>
                            Reorder</Link>
                    }
                    <Link className={"btn " + (pathname.endsWith('y-cards') ? "btn-primary" : "btn-outline-primary")}
                        to={`/dictionaries/${id}/beautify-cards`}
                        state={dictionary}>
                        Beautify</Link>
                </ButtonGroup>
            </div>
        );
    }
    else
        return <></>
}
