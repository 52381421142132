import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { Language } from '../../types/Common';
import { State } from '../../types/State';
import AutoPaginationTable from '../general/AutoPaginationTable';
import Error from '../general/Error';
import Loader from '../general/Loader';

export default function LangList() {
    const auth = useSelector((state: State) => state.auth);

    const [languages, setLanguages] = React.useState<Language[]>();

    const [getting, startGetting] = useFetchOperation(setLanguages, undefined, true);

    React.useEffect(() => {
        startGetting('get', `api/languages`);
        document.title = `Languages - ${(document as any).rootTitle}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: ColumnDef<Language>[] = React.useMemo(() => [
        {
            header: 'Native name',
            accessorKey: 'name',
            cell: props => auth.isModerator ? < Link to={`/languages/${props.row.original.id}`
            } > {props.row.original.name}</Link > : <>{props.row.original.name}</>
        },
        {
            header: 'Code',
            accessorKey: 'code',
        },
        {
            header: 'Wiktionary English name',
            accessorKey: 'wiktionaryName',
        },
        {
            header: 'Reverso url name',
            accessorKey: 'reversoName',
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    return (
        <>
            {getting.active ?
                <Loader />
                :
                getting.error ?
                    <Error text={getting.error} />
                    :
                    <div className="w-100">
                        <h1 className='mb-3'>Languages</h1>

                        {auth.isModerator &&
                            <div className="btn-panel">
                                <Link to={`/languages/add`}
                                    className="btn btn-outline-primary btn-sm">Add language</Link>
                            </div>
                        }

                        {languages ?
                            <AutoPaginationTable
                                columns={columns}
                                data={languages}
                                className="my-3" />
                            :
                            <p className="my-3">No languages found</p>
                        }
                    </div>
            }
        </>
    )
}
