import Moment from 'react-moment';

export const apiDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const apiDateFormat = 'YYYY-MM-DD';

export const uiDateTimeFormat = 'YYYY‑MM‑DD HH:mm:ss'; // with unbreakable hyphens
export const uiDateFormat = 'YYYY‑MM‑DD'; // with unbreakable hyphens

export function LocalDateTime(props: { value: string | undefined }) {
    if (!props.value)
        return <></>
    else
        return <Moment
            format={uiDateTimeFormat}
            utc
            local>
            {props.value}
        </Moment>
}

export function LocalDate(props: { value: string }) {
    if (!props.value)
        return <></>
    else
        return <Moment
            format={uiDateFormat}>
            {props.value}
        </Moment>
}
