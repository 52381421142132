import * as React from "react";

export default function DiffSpan(props: {
    value: number | undefined,
    oldValue: number | undefined,
    addPlus?: boolean,
    inverse?: boolean,
    title?: string,
    valuePrecision?: number | undefined
}) {
    const { value, oldValue, addPlus, inverse, title, valuePrecision } = props;
    let className = (oldValue === undefined || isNaN(oldValue) || value === oldValue) ? '' : (value !== undefined && oldValue !== undefined && value > oldValue) !== !inverse ? 'red' : 'green';

    let valueStr = value?.toString();
    if (valuePrecision)
        valueStr = value?.toFixed(valuePrecision)
    return <span title={title} className={className}>{addPlus && value !== undefined && value >= 0 && '+'}{valueStr}</span>;
}