import * as React from 'react';
import { useFetchOperation } from '../../../service/Operation';
import { CardResultStatus, CardStatus, CountData, ExtendedResult } from '../../../types/Common';
import ProgressButton from '../../general/ProgressButton';

interface UserCardStatusResponse {
    skipAdded: boolean,
    skipDeleted: boolean,
    resultAdded: boolean,
    resultDeleted: boolean,
    cardResult: CardResultStatus
}

export default function AddToStudying(props: {
    cardId: number,
    isDirect: boolean,
    userId: number,
    disabled: boolean | undefined,
    onAdded?: (result: ExtendedResult) => void
}) {
    const [addToStudying, startAddToStudying] = useFetchOperation(onAddedToStudyingSuccess);
    const [addedToStudying, setAddedToStudying] = React.useState<boolean>();

    function onAddToStudyingClick() {
        const body = {
            userId: props.userId,
            cardId: props.cardId,
            isDirect: props.isDirect,
            status: CardStatus.ActiveStudying
        }
        startAddToStudying('post', `api/user/card-status`, body);
    }

    function onAddedToStudyingSuccess(data: UserCardStatusResponse) {
        setAddedToStudying(data.resultAdded)
        if (data.resultAdded && props.onAdded)
            props.onAdded(data.cardResult.result!);
    }

    return (
        <ProgressButton variant="sm"
            className="btn-outline-primary"
            onClick={onAddToStudyingClick}
            disabled={props.disabled || addToStudying.success}
            active={addToStudying.active}
            success={addToStudying.success}
            text='Add to studying'
            successText={addedToStudying ? 'Added to studying' : 'Already in studying'} />
    );
}
