import * as React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useFetchOperation } from '../../../service/Operation';
import { useAppSelector } from '../../../store';
import { CountData } from '../../../types/Common';
import Alerts from '../../general/Alerts';
import ProgressButton from '../../general/ProgressButton';

export interface UserMode {
    userId: number,
    dictionaryId: number,
    isDirect: boolean
}

export function UserCardsButtonPanel(props: {
    userMode: UserMode;
    unskipData: () => void;
    deleteData: () => void;
}) {
    const auth = useAppSelector(state => state.auth);

    const { userId, dictionaryId, isDirect } = props.userMode;

    const [studyAllModalShow, setStudyAllModalShow] = React.useState(false);

    const [editing, startEditing, resetEditing] = useFetchOperation(onEditingSuccess);

    function onStudyAllClick() {
        resetDeleting();
        setStudyAllModalShow(false);
        startEditing('post', `api/user/unskip-all-cards`, props.userMode);
    }

    function onEditingSuccess(data: CountData) {
        if (data?.count)
            props.unskipData();
    }

    const [deleteModalShow, setDeleteModalShow] = React.useState(false);
    const [deletedCount, setDeletedCount] = React.useState();

    const [deleting, startDeleting, resetDeleting] = useFetchOperation(result => setDeletedCount(result.count));

    async function onDeleteClick() {
        resetEditing();
        setDeleteModalShow(false);
        startDeleting('delete', `api/results/delete-all?userId=${userId}&dictionaryId=${dictionaryId}&isDirect=${isDirect}`);
        props.deleteData();
    }

    return (
        <>
            <div className="btn-panel">
                <ProgressButton variant="outline-primary"
                    className="btn-wide"
                    onClick={() => setStudyAllModalShow(true)}
                    disabled={deleting.active}
                    active={editing.active}
                    success={editing.success}
                    text="Study all cards" />

                <Modal show={studyAllModalShow}
                    onHide={() => setStudyAllModalShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Study all cards</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="fw-bold">Are you sure you want to study all cards in this mode?</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary"
                            onClick={() => setStudyAllModalShow(false)}>Cancel</Button>
                        <Button variant="danger"
                            onClick={onStudyAllClick}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                {auth.isAdmin &&
                    <ProgressButton variant="outline-danger"
                        className="btn-wide"
                        onClick={() => setDeleteModalShow(true)}
                        active={editing.active}
                        success={editing.success}
                        text="Delete all results in this mode"
                        successText="All results deleted" />}
                <Modal show={deleteModalShow}
                    onHide={() => setDeleteModalShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Danger!</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="fw-bold">Are you sure you want to delete all results in this mode?</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary"
                            onClick={() => setDeleteModalShow(false)}>Cancel</Button>
                        <Button variant="danger"
                            onClick={onDeleteClick}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Alerts success={editing.success}
                    successContent="All cards were successfully set to studying"
                    error={editing.error}
                    onClose={() => resetEditing()} />

                <Alerts success={deleting.success}
                    successContent={deletedCount + ' results in this mode were successfully deleted'}
                    error={deleting.error}
                    onClose={() => resetDeleting()} />
            </div>
        </>
    );
}
