import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { statActionCreators } from '../../../store/Stat';
import TodayYesterdayDiff from './TodayYesterdayDiff';
import { CurrentModeStat } from './CurrentModeStat';
import { LastResultStat } from './LastResultStat';
import { ChangeModeButton } from './ChangeModeButton';

export default function Stat() {
    const dispatch = useAppDispatch();
    const {
        modeStat,
        previousModeStat,
        todayStat,
        previousTodayStat,
        yesterdayStat,
        mixedReadyCount,
        currentResultInfo,
        lastResult,
        cardsInfo,
        cardsInfoUpdated,
        isModeStatLoading,
    } = useAppSelector(state => state.stat);
    const modesState = useAppSelector(state => state.modes);
    const isModeChangingActive = useAppSelector(state => state.mode.isModeChangingActive);
    const isAuthenticated = useAppSelector(state => !!state.auth.user);

    const modeChanged = modeStat?.dictionaryId !== previousModeStat?.dictionaryId || modeStat?.isDirect !== previousModeStat?.isDirect;

    React.useEffect(() => {
        if (isAuthenticated
            && modesState.modes.length === 0
            && !isModeStatLoading
            && !modeStat) {
            dispatch(statActionCreators.getModeStat());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='lh-sm mt-1 mb-2' style={{ minHeight: '5.4rem' }}>
            {isModeChangingActive ?
                <div className="fs-5">Changing mode...</div>
                :
                isModeStatLoading ?
                    <div className="fs-5">Loading active mode...</div>
                    :
                    <>
                        {modeStat &&
                            <>
                                <ChangeModeButton modeStat={modeStat} />

                                <CurrentModeStat modeStat={modeStat}
                                    previousModeStat={modeChanged ? undefined : previousModeStat}
                                    mixedReadyCount={mixedReadyCount} />
                            </>
                        }

                        <div>
                            {cardsInfo &&
                                <span className={cardsInfoUpdated ? 'green' : undefined}>{cardsInfo}</span>
                            }
                            {cardsInfo && todayStat &&
                                <>&thinsp;|&thinsp;</>
                            }
                            {todayStat ?
                                <TodayYesterdayDiff
                                    modeStat={modeStat}
                                    todayStat={todayStat}
                                    yesterdayStat={yesterdayStat}
                                    previousModeStat={modeChanged ? undefined : previousModeStat}
                                    previousTodayStat={modeChanged ? undefined : previousTodayStat} />
                                :
                                <span>&nbsp;</span>
                            }
                        </div>

                        <div>
                            {currentResultInfo && currentResultInfo.info &&
                                <Link to={`/results/${currentResultInfo.id}`}>{currentResultInfo.info}</Link>
                            }
                            {currentResultInfo && !currentResultInfo.info &&
                                <Link to={`/cards/${currentResultInfo.id}`} className="me-1">new</Link>
                            }
                            &nbsp;
                            {lastResult && lastResult.id &&
                                <LastResultStat lastResult={lastResult} />
                            }
                        </div>

                    </>
            }
        </div>
    );
}

