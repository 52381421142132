import React, { ChangeEvent } from 'react';
import { Alert, Button, CloseButton, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import Alerts from '../general/Alerts';
import ProgressButton from '../general/ProgressButton';
import ModeSelector from './ModeSelector';
import { ModeSetting, ModeSettingSlim, UserModesSettings } from '../../types/TrainingTypes';
import { Quantities } from './Enums';

export default function Mixed(props: {
    settings: UserModesSettings,
    setSettings: (x: UserModesSettings) => void
}) {
    const [settings, setSettings] = React.useState(props.settings.mixed);
    const dictionaries = props.settings.dictionaries;

    const [newMixed, setNewMixed] = React.useState({
        dictionaryId: dictionaries[0].id,
        dictionaryName: dictionaries[0].name,
        isDirect: true
    });

    const [getting, startGetting, resetGetting, setGettingError] = useFetchOperation(setModeSettings);
    const [editing, startEditing, resetEditing, setEditingError] = useFetchOperation(() => { setTimeout(resetEditing, 2000) });

    function onDictionaryChange(dictionaryId: number, dictionaryName: string) {
        resetEditing()
        setNewMixed({ ...newMixed, dictionaryId, dictionaryName });
    }

    function onIsDirectChange(isDirect: boolean) {
        resetEditing()
        setNewMixed({ ...newMixed, isDirect });
    }

    function onAddModeClick() {
        if (!settings!.find(m => m.dictionaryId === newMixed.dictionaryId
            && m.isDirect === newMixed.isDirect)) {
            startGetting('get', `api/study/settings/mode-setting?dictionaryId=${newMixed.dictionaryId}&isDirect=${newMixed.isDirect}`);
        }
        else {
            setGettingError('This mode was already added');
        }
    }

    function setModeSettings(data: ModeSettingSlim) {
        const setting: ModeSetting = {
            ...newMixed,
            randomOrder: data.randomOrder,
            studyLimit: data.studyLimit,
            newCardsQuantity: data.newCardsQuantity,
            taskKind: data.taskKind
        };
        setSettings([...settings!, setting]);
    }

    function onSettingRemove(setting: ModeSetting) {
        const newSettings: ModeSetting[] = [];
        settings!.forEach(x => {
            if (x.dictionaryId !== setting.dictionaryId || x.isDirect !== setting.isDirect)
                newSettings.push(x);
        })
        setSettings(newSettings);
    }

    function onSettingChanged(setting: ModeSetting) {
        const newSettings: ModeSetting[] = [];
        settings!.forEach(x => {
            if (x.dictionaryId !== setting.dictionaryId || x.isDirect !== setting.isDirect)
                newSettings.push(x);
            else
                newSettings.push(setting);
        })
        setSettings(newSettings);
    }

    function saveMixed() {
        if (settings!.length > 0) {
            startEditing('post', `api/study/settings/mixed`, {
                modeSettings: settings,
            });
        }
        else {
            setEditingError("No modes were added to the list");
        }
    }

    return (
        <div>
            <div>
                {!settings?.length ?
                    <p className="my-3">List of the modes in the mix is empty. Please add modes!</p>
                    :
                    <ul className="list-group mb-2">
                        {settings.map(setting =>
                            <li className="list-group-item border-0 p-0 mb-1 mb-sm-2"
                                key={setting.dictionaryId.toString() + setting.isDirect}>
                                <MixedModeSetting setting={setting}
                                    onChange={onSettingChanged}
                                    onRemove={onSettingRemove} />
                            </li>
                        )}
                    </ul>
                }
                <div className="border border-primary rounded-3 bg-light w-auto mx-auto pt-1 pb-2 px-3 d-inline-block">

                    <ModeSelector dictionaries={dictionaries}
                        dictionaryId={newMixed.dictionaryId}
                        isDirect={newMixed.isDirect}
                        onDictionaryChange={onDictionaryChange}
                        onIsDirectChange={onIsDirectChange} />

                    <Button className="my-1"
                        variant="outline-primary"
                        onClick={onAddModeClick}
                        disabled={editing.active} >
                        Add mode to the list
                    </Button>

                    {getting.error &&
                        <Alert dismissible
                            variant="danger"
                            className="my-3"
                            onClose={() => resetGetting()}>
                            {getting.error}
                        </Alert>
                    }
                </div>
            </div>

            <div className="btn-panel">
                <ProgressButton className="btn-wide"
                    onClick={saveMixed}
                    active={editing.active}
                    success={editing.success} />
            </div>

            <Alerts
                error={editing.error}
                onClose={() => resetEditing()} />
        </div>
    )
}

function MixedModeSetting(props: {
    setting: ModeSetting,
    onChange: (x: ModeSetting) => void,
    onRemove: (x: ModeSetting) => void
}) {

    function studyLimitChanged(e: ChangeEvent<HTMLInputElement>) {
        const studyLimit = e.target.value === '' ? 0 : parseInt(e.target.value);
        props.onChange({ ...props.setting, studyLimit });
    }

    function onRandomOrderClick() {
        props.onChange({ ...props.setting, randomOrder: !props.setting.randomOrder });
    }

    function onNewCardsQuantityChanged(e: ChangeEvent<HTMLSelectElement>) {
        const value = parseInt(e.target.value);
        props.onChange({ ...props.setting, newCardsQuantity: value });
    }

    function onRemoveClick() {
        props.onRemove(props.setting);
    }

    return (
        <div>
            <Link to={`/dictionaries/${props.setting.dictionaryId}`}
                className="align-middle mb-1 mb-sm-0">{props.setting.dictionaryName}</Link>,<> </>

            <span className="align-middle me-2">
                {props.setting.isDirect ? "direct" : "inverse"}
            </span>

            <div className='d-block d-sm-inline-block '>

                <Form.Select
                    id="newCardsQuantity"
                    name="newCardsQuantity"
                    value={props.setting.newCardsQuantity}
                    className="d-inline-block w-auto me-2 py-1 align-middle"
                    onChange={onNewCardsQuantityChanged}>
                    {Quantities.map(x =>
                        <option value={x[0]} key={x[0]}>{x[1]}</option>)}
                </Form.Select>

                <Form.Control type="number"
                    placeholder="no limit"
                    className="d-inline p-1 align-middle me-2"
                    value={props.setting.studyLimit > 0 ? props.setting.studyLimit : ''}
                    onChange={studyLimitChanged}
                    style={{ width: '95px' }} />

                <Button variant="outline-primary"
                    size="sm"
                    className="align-middle me-2"
                    onClick={onRandomOrderClick}>
                    {props.setting.randomOrder ? "random" : "normal"}
                </Button>

                <CloseButton className="align-middle"
                    onClick={onRemoveClick} />
            </div>
        </div>
    );
}
