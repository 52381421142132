import * as moment from 'moment';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFetchOperation } from '../../../service/Operation';
import { UserMode } from '../../../types/Common';
import { apiDateFormat } from '../../formatters/DateTimeFormat';
import Alerts from '../../general/Alerts';
import Error from '../../general/Error';
import Loader from '../../general/Loader';
import useOnChange from '../../general/OnChange';
import ProgressButton from '../../general/ProgressButton';
import UserPanel from '../UserPanel';


interface EditDayStat {
    userMode: UserMode,
    date: string,
    updations: number,
    avgStrength: number,
    weakDeltaSum: number,
    interpretShare: number | undefined,
    memorized: number,
    weak: number,
    medium: number,
    strong: number,
    onHold: number,
    //calculated:
    studied: number,
    memorizedPercent: number,
}

export default function DayStatEdit() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [historyStat, setHistoryStat] = React.useState<EditDayStat>();
    const { onNumberChange } = useOnChange(historyStat, setHistoryStat);

    const [getting, startGetting] = useFetchOperation(onGetSuccess, undefined, true);
    const [editing, startEditing, resetEditing] = useFetchOperation(onEditingSuccess);

    React.useEffect(() => {
        const isDirect = searchParams.get('direct')?.toLowerCase() !== 'false';
        startGetting('get', `api/user/${params.id}/history/${params.date}?dictionaryId=${params.dictionaryId}&isDirect=${isDirect}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, searchParams]);

    function onGetSuccess(data: EditDayStat) {
        calculateModelFields(data);
        setHistoryStat(data);
    }

    function calculateModelFields(hs: EditDayStat) {
        hs.studied = hs.weak + hs.medium + hs.strong + hs.onHold;
        hs.memorizedPercent = hs.memorized / hs.studied * 100;
        hs.date = moment.utc(hs.date).format(apiDateFormat);
    }

    function saveClick() {
        startEditing('put', `api/user/${historyStat?.userMode?.userId}/history`, historyStat);
    }

    function onEditingSuccess() {
        const hs = { ...historyStat! };
        calculateModelFields(hs);
        setHistoryStat(hs);
        setTimeout(resetEditing, 2000);
    }

    return (
        <>
            <UserPanel userMode={historyStat?.userMode} />

            {getting.active ?
                <Loader />
                :
                !historyStat ?
                    <Error text={getting.error} />
                    :
                    <div className="width-limit">
                        <h2 className="mt-3 mb-0">Edit history item</h2>

                        <Form className="form-large width-limit mb-3">

                            <div className="row gx-3">
                                <Form.Group
                                    className="col-12 col-sm-6">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control readOnly
                                        value={historyStat.date} />
                                </Form.Group>

                                <Form.Group
                                    className="col-12 col-sm-6">
                                    <Form.Label>Passed</Form.Label>
                                    <Form.Control readOnly
                                        value={historyStat.studied} />
                                </Form.Group>


                                <Form.Group controlId="updations"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Number of updates</Form.Label>
                                    <Form.Control autoComplete="off"
                                        value={historyStat.updations}
                                        onChange={onNumberChange} />
                                </Form.Group>

                                <Form.Group controlId="avgStrength"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Average strength</Form.Label>
                                    <Form.Control autoComplete="off"
                                        value={historyStat.avgStrength}
                                        onChange={onNumberChange} />
                                </Form.Group>


                                <Form.Group controlId="memorized"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Memorized</Form.Label>
                                    <Form.Control autoComplete="off"
                                        value={historyStat.memorized}
                                        onChange={onNumberChange} />
                                </Form.Group>

                                <Form.Group
                                    className="col-12 col-sm-6">
                                    <Form.Label>Memorized percent</Form.Label>
                                    <Form.Control readOnly
                                        value={historyStat.memorizedPercent.toFixed(2)} />
                                </Form.Group>


                                <Form.Group controlId="strong"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Strong</Form.Label>
                                    <Form.Control autoComplete="off"
                                        value={historyStat.strong}
                                        onChange={onNumberChange} />
                                </Form.Group>

                                <Form.Group controlId="medium"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Medium</Form.Label>
                                    <Form.Control autoComplete="off"
                                        value={historyStat.medium}
                                        onChange={onNumberChange} />
                                </Form.Group>

                                <Form.Group controlId="weak"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Weak</Form.Label>
                                    <Form.Control autoComplete="off"
                                        value={historyStat.weak}
                                        onChange={onNumberChange} />
                                </Form.Group>


                                <Form.Group controlId="onHold"
                                    className="col-12 col-sm-6">
                                    <Form.Label>On hold</Form.Label>
                                    <Form.Control autoComplete="off"
                                        value={historyStat.onHold}
                                        onChange={onNumberChange} />
                                </Form.Group>


                                <Form.Group controlId="weakDeltaSum"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Weak delta sum</Form.Label>
                                    <Form.Control autoComplete="off"
                                        value={historyStat.weakDeltaSum}
                                        onChange={onNumberChange} />
                                </Form.Group>

                                <Form.Group controlId="interpretShare"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Share where Study is last</Form.Label>
                                    <Form.Control autoComplete="off"
                                        value={historyStat.interpretShare}
                                        onChange={onNumberChange} />
                                </Form.Group>
                            </div>

                            <div className="btn-panel">
                                <ProgressButton className="mx-2 my-1 btn-wide"
                                    onClick={saveClick}
                                    active={editing.active}
                                    success={editing.success} />
                            </div>

                            <Alerts error={editing.error}
                                onClose={() => resetEditing()} />
                        </Form>
                    </div>
            }
        </>
    )
}
