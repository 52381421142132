import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '.';
import apiClient from '../service/ApiClient';
import { ModeStatSlim } from '../types/StatTypes';

export interface StatState {
    isModesLoading: boolean,
    modes: ModeStatSlim[],
}

const initialState: StatState = {
    isModesLoading: false,
    modes: [],
}

const slice = createSlice({
    name: 'modes',
    initialState,
    reducers: {
        start: (state) => {
            state.isModesLoading = true;
        },

        success: (state, action: PayloadAction<ModeStatSlim[]>) => {
            state.isModesLoading = false;
            state.modes = action.payload;
        },

        fail: state => {
            state.isModesLoading = false;
        },
    },
})

const { start, success, fail } = slice.actions;

const getModes = () => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(start());
    apiClient.get('/api/stat/slim-mode-stat')
        .then((data: ModeStatSlim[]) => {
            dispatch(success(data));
        })
        .catch(() => {
            dispatch(fail())
        });
}

export const modesReducer = slice.reducer;

export const modesActionCreators = {
    getModes
}
