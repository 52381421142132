import React from "react";
import apiClient, { Method } from './ApiClient';

export interface OperationState {
    active: boolean,
    success: boolean | undefined,
    error: string | undefined,
}

export type Operation = [
    OperationState,
    () => void,
    (error: string) => void,
    () => void,
    () => void
]

export const useOperation = (active: boolean = false): Operation => {
    const [state, setState] = React.useState<OperationState>({
        active: active,
        success: undefined,
        error: undefined
    });

    const start = () => setState({
        active: true,
        success: undefined,
        error: undefined
    });

    const reset = () => setState({
        active: false,
        success: undefined,
        error: undefined
    });

    const setSuccess = () => setState({
        active: false,
        success: true,
        error: undefined
    });

    const setError = (error: string) => setState({
        active: false,
        success: undefined,
        error: error
    });

    return [state, setSuccess, setError, start, reset];
}

export type FetchOperation = [
    OperationState,
    (method: Method, url: string, body?: any) => void,
    () => void,
    (error: string) => void,
]

export function useFetchOperation(
    successCallback?: (data: any) => string | undefined | void,
    errorCallback?: (data: string) => string | undefined | void,
    active?: boolean
): FetchOperation {
    const [state, setSuccess, setError, start, reset] = useOperation(active);

    const startOperation = (method: Method, url: string, data?: any) => {
        start();
        apiClient.fetch(method, url, data)
            .then(data => {
                if (successCallback) {
                    const callbackResult = successCallback(data);
                    if (callbackResult)
                        throw new Error(callbackResult);
                }
                setSuccess();
            })
            .catch(error => {
                let message = String(error.message);
                if (errorCallback) {
                    const callbackResult = errorCallback(error.message);
                    if (callbackResult)
                        message = callbackResult;
                }
                setError(message);
            });
    };
    return [state, startOperation, reset, setError];
}
