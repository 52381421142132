import * as React from 'react';
import { Form } from 'react-bootstrap';
import { ModeSettingSlim } from '../../types/TrainingTypes';
import useOnChange from '../general/OnChange';
import { Quantities, TaskKinds } from './Enums';

export default function ModeSettingEditor(props: {
    setting: ModeSettingSlim,
    setSetting: (x: ModeSettingSlim) => void,
    isGetting: boolean
}) {
    const { onNumberChange, onBooleanChange } = useOnChange(props.setting, props.setSetting);

    return <>
        <div className="my-2">
            <Form.Label htmlFor="taskKind" className="me-2">Card visibility:</Form.Label>
            <Form.Select
                id="taskKind"
                name="taskKind"
                value={props.setting.taskKind}
                className="d-inline-block w-auto"
                disabled={props.isGetting}
                onChange={onNumberChange}>
                {TaskKinds.map(x => <option value={x[0]} key={x[0]}>{x[1]}</option>)}
            </Form.Select>
        </div>

        <div className="my-2">
            <Form.Label htmlFor="newCardsQuantity" className="me-2">Quantity of new cards:</Form.Label>
            <Form.Select
                id="newCardsQuantity"
                name="newCardsQuantity"
                value={props.setting.newCardsQuantity}
                className="d-inline-block w-auto"
                disabled={props.isGetting}
                onChange={onNumberChange}>
                {Quantities.map(x => <option value={x[0]} key={x[0]}>{x[1]}</option>)}
            </Form.Select>
        </div>

        <div className="my-2">
            <Form.Label htmlFor="studyLimit" className="me-2">Daily limit of new cards</Form.Label>
            <Form.Control type="number"
                id="studyLimit"
                name="studyLimit"
                className="d-inline-block"
                disabled={props.isGetting}
                onChange={onNumberChange}
                value={props.setting.studyLimit > 0 ? props.setting.studyLimit : ''}
                placeholder="no limit"
                style={{ width: '100px' }} />
        </div>

        <Form.Check
            className="d-inline-block mb-2"
            type="checkbox"
            id="randomOrder"
            name="randomOrder"
            disabled={props.isGetting}
            checked={props.setting.randomOrder}
            onChange={onBooleanChange}
            label="Random order of new cards" />
    </>;
}
